import { useCallback } from 'react';
import useSWR from 'swr';

export function useEvents(selectedVehicle: string) {
  const { data, error, mutate } = useSWR<Event[]>(`/api/events?vehicle=${selectedVehicle}`);

  return {
    events: data || [],
    isLoading: !error && !data,
    mutate
  };
}