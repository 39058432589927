import { useState, useEffect } from 'react';
import { Vehicle } from '@/types';
import { supabase } from '@/lib/supabaseClient';

export function useVehicles() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchVehicles() {
      setIsLoading(true);
      const { data, error } = await supabase.from('vehicles').select('*');

      if (error) {
        console.error('Error fetching vehicles:', error);
        setVehicles([]);
      } else {
        setVehicles(data as Vehicle[]);
      }
      setIsLoading(false);
    }

    fetchVehicles();
  }, []);

  return { vehicles, isLoading };
}