import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Clock, CreditCard, FileCheck, User, LucideIcon } from 'lucide-react';

interface RequirementCardProps {
  title: string;
  content: string;
  icon: LucideIcon;
}

const RequirementCard: React.FC<RequirementCardProps> = ({ title, content, icon: Icon }) => (
  <Card className="mb-6">
    <CardHeader>
      <CardTitle className="flex items-center text-2xl font-bold">
        <Icon className="mr-2" />
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-lg">{content}</p>
    </CardContent>
  </Card>
);

const Requirements = () => (
  <div className="container mx-auto px-4 py-8">
    <h1 className="text-5xl font-bold text-center mb-8 uppercase tracking-wider">
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 font-montserrat">
        RENTAL REQUIREMENTS
      </span>
    </h1>

    <p className="text-xl text-center mb-8">
      To ensure a smooth rental experience, please review our requirements before making a reservation:
    </p>

    <RequirementCard
      title="Age Requirement"
      content="All drivers must be at least 25 years old to rent a vehicle from AR Royal Rentals."
      icon={User}
    />

    <RequirementCard
      title="Driver's License"
      content="A valid driver's license is required for all drivers. International licenses are accepted if they are in English or accompanied by an official translation."
      icon={CreditCard}
    />

    <RequirementCard
      title="Credit Card"
      content="A major credit card in the renter's name is required for the security deposit. We accept Visa, MasterCard, and American Express."
      icon={CreditCard}
    />

    <RequirementCard
      title="Insurance"
      content="Proof of insurance is required. We offer additional insurance options if needed."
      icon={FileCheck}
    />

    <RequirementCard
      title="Reservation"
      content="We recommend making a reservation in advance to ensure vehicle availability, especially during peak seasons."
      icon={Clock}
    />

    <p className="mt-8 text-center text-lg text-gray-600">
      For any questions about our requirements, please contact our customer service team.
    </p>
  </div>
);

export default Requirements;