import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Menu, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useUser, useClerk } from '@clerk/clerk-react';
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { Database } from '@/types/supabase';

interface DashboardData {
  stats: {
    total_bookings: number;
    active_rentals: number;
    total_revenue: number;
  };
  recentBookings: Array<{
    id: string;
    customers: {
      first_name: string;
      last_name: string;
    };
    vehicles: {
      make: string;
      model: string;
    };
    start_date: string;
    end_date: string;
    total_price: number;
    status: string;
  }>;
  upcomingReturns: Array<{
    id: string;
    customer_name: string;
    vehicle: string;
    end_date: string;
    email: string;
    phone_number: string;
  }>;
}

const AdminDashboard: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { signOut } = useClerk();
  const supabase = createClientComponentClient<Database>();

  useEffect(() => {
    if (!user || user.publicMetadata.role !== 'admin') {
      console.log('No admin user found, redirecting to login');
      navigate('/sign-in');
    } else {
      console.log('Admin user found:', user.id);
      fetchDashboardData();
    }
  }, [user, navigate]);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [{ data: statsData, error: statsError }, { data: recentBookings, error: bookingsError }, { data: upcomingReturns, error: returnsError }] = await Promise.all([
        supabase.rpc('get_admin_dashboard_stats'),
        supabase.from('bookings').select('id, start_date, end_date, total_cost, status, customers(first_name, last_name), vehicles(make, model)').order('created_at', { ascending: false }).limit(5),
        supabase.rpc('get_upcoming_returns')
      ]);

      if (statsError) throw statsError;
      if (bookingsError) throw bookingsError;
      if (returnsError) throw returnsError;

      if (!statsData || statsData.length === 0) {
        throw new Error('No stats data returned');
      }

      const stats = {
        total_bookings: statsData[0].total_bookings ?? 0,
        active_rentals: statsData[0].active_rentals ?? 0,
        total_revenue: statsData[0].total_revenue ?? 0
      };

      setDashboardData({
        stats,
        recentBookings: recentBookings?.map(booking => ({
          id: booking.id.toString(),
          customers: {
            first_name: booking.customers?.[0]?.first_name ?? '',
            last_name: booking.customers?.[0]?.last_name ?? ''
          },
          vehicles: {
            make: booking.vehicles?.make ?? '',
            model: booking.vehicles?.model ?? ''
          },
          start_date: booking.start_date ?? '',
          end_date: booking.end_date ?? '',
          total_price: booking.total_cost ?? 0,
          status: booking.status ?? ''
        })) ?? [],
        upcomingReturns: upcomingReturns ?? []
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again. Error: ' + (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBookingAction = async (bookingId: string, action: string, reason: string = '') => {
    try {
      const { data, error } = await supabase
        .rpc('admin_approve_booking', { booking_id: bookingId, action, reason });

      if (error) throw error;

      if (data && typeof data === 'object' && 'success' in data) {
        if (data.success) {
          fetchDashboardData();
        } else {
          throw new Error((data as { message?: string }).message || `Failed to ${action} booking`);
        }
      } else {
        throw new Error(`Unexpected response format when trying to ${action} booking`);
      }
    } catch (error) {
      console.error(`Error ${action}ing booking:`, error);
      setError(`Failed to ${action} booking. Please try again.`);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async () => {
    await signOut();
    navigate('/sign-in');
  };

  const navItems = [
    { to: "/admin", label: "Overview" },
    { to: "/admin/bookings", label: "Bookings" },
    { to: "/admin/customers", label: "Customers" },
    { to: "/admin/extension-requests", label: "Extension Requests" },
    { to: "/admin/settings", label: "Settings" },
    { to: "/admin/vehicle-management", label: "Vehicle Management" },
    { to: "/admin/reporting-analytics", label: "Reporting & Analytics" },
    { to: "/admin/loyalty-program", label: "Loyalty Program" },
    { to: "/admin/vehicle-maintenance", label: "Vehicle Maintenance" },
    { to: "/admin/create-booking", label: "Create Booking" },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside 
        className={`
          bg-white shadow-md w-64 fixed inset-y-0 left-0 transform 
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
          md:relative md:translate-x-0 transition duration-200 ease-in-out z-30
          flex flex-col
        `}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-xl font-bold">Admin Dashboard</h2>
          <Button 
            onClick={toggleSidebar} 
            className="md:hidden"
            variant="ghost"
            size="sm"
          >
            <X className="h-6 w-6" />
          </Button>
        </div>
        <nav className="flex-grow p-4 space-y-2 overflow-y-auto">
          {navItems.map((item) => (
            <Link 
              key={item.to} 
              to={item.to}
              className={`
                block w-full px-4 py-2 text-left transition-colors duration-200
                rounded-md shadow-sm
                ${location.pathname === item.to 
                  ? 'bg-red-600 text-white' 
                  : 'bg-white text-gray-700 hover:bg-red-100'
                }
              `}
              onClick={() => setIsSidebarOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      </aside>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-white shadow-sm p-4 flex justify-between items-center">
          <Button 
            onClick={toggleSidebar} 
            className="md:hidden"
            variant="ghost"
            size="sm"
          >
            <Menu className="h-6 w-6" />
          </Button>
          <h1 className="text-xl font-semibold">
            {navItems.find(item => item.to === location.pathname)?.label || 'Admin Dashboard'}
          </h1>
          <Button onClick={handleLogout}>Logout</Button>
        </header>
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : location.pathname === "/admin" && dashboardData ? (
            <>
              {/* Dashboard Overview */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
                <Card>
                  <CardHeader>
                    <CardTitle>Total Bookings</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-3xl font-bold">{dashboardData.stats.total_bookings}</p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Active Rentals</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-3xl font-bold">{dashboardData.stats.active_rentals}</p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Total Revenue</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-3xl font-bold">${parseFloat(dashboardData.stats.total_revenue.toString()).toFixed(2)}</p>
                  </CardContent>
                </Card>
              </div>

              {/* Recent Bookings */}
              <Card className="mb-8">
                <CardHeader>
                  <CardTitle>Recent Bookings</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Customer</TableHead>
                        <TableHead>Vehicle</TableHead>
                        <TableHead>Start Date</TableHead>
                        <TableHead>End Date</TableHead>
                        <TableHead>Total Price</TableHead>
                        <TableHead>Status</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dashboardData.recentBookings.map((booking) => (
                        <TableRow key={booking.id}>
                          <TableCell>{`${booking.customers.first_name} ${booking.customers.last_name}`}</TableCell>
                          <TableCell>{`${booking.vehicles.make} ${booking.vehicles.model}`}</TableCell>
                          <TableCell>{booking.start_date}</TableCell>
                          <TableCell>{booking.end_date}</TableCell>
                          <TableCell>${booking.total_price}</TableCell>
                          <TableCell>{booking.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>

              {/* Upcoming Returns */}
              <Card>
                <CardHeader>
                  <CardTitle>Upcoming Returns</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Customer</TableHead>
                        <TableHead>Vehicle</TableHead>
                        <TableHead>Return Date</TableHead>
                        <TableHead>Contact</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dashboardData.upcomingReturns.map((returnItem) => (
                        <TableRow key={returnItem.id}>
                          <TableCell>{returnItem.customer_name}</TableCell>
                          <TableCell>{returnItem.vehicle}</TableCell>
                          <TableCell>{returnItem.end_date}</TableCell>
                          <TableCell>
                            <a href={`mailto:${returnItem.email}`} className="text-blue-500 hover:underline mr-2">Email</a>
                            <a href={`tel:${returnItem.phone_number}`} className="text-blue-500 hover:underline">Call</a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          ) : (
            <Outlet />
          )}
        </main>
      </div>

      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden" 
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default AdminDashboard;