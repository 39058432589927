import React, { HTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from 'react'

interface TableProps extends HTMLAttributes<HTMLTableElement> {}

const Table = React.forwardRef<HTMLTableElement, TableProps>(({ ...props }, ref) => (
  <div className="w-full overflow-auto">
    <table ref={ref} className="w-full caption-bottom text-sm" {...props} />
  </div>
))
Table.displayName = "Table"

interface TableHeaderProps extends HTMLAttributes<HTMLTableSectionElement> {}

const TableHeader = React.forwardRef<HTMLTableSectionElement, TableHeaderProps>(({ ...props }, ref) => (
  <thead ref={ref} className="[&_tr]:border-b" {...props} />
))
TableHeader.displayName = "TableHeader"

interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {}

const TableBody = React.forwardRef<HTMLTableSectionElement, TableBodyProps>(({ ...props }, ref) => (
  <tbody ref={ref} className="[&_tr:last-child]:border-0" {...props} />
))
TableBody.displayName = "TableBody"

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {}

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(({ ...props }, ref) => (
  <tr
    ref={ref}
    className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
    {...props}
  />
))
TableRow.displayName = "TableRow"

interface TableHeadProps extends ThHTMLAttributes<HTMLTableHeaderCellElement> {}

const TableHead = React.forwardRef<HTMLTableHeaderCellElement, TableHeadProps>(({ ...props }, ref) => (
  <th
    ref={ref}
    className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0"
    {...props}
  />
))
TableHead.displayName = "TableHead"

interface TableCellProps extends TdHTMLAttributes<HTMLTableDataCellElement> {}

const TableCell = React.forwardRef<HTMLTableDataCellElement, TableCellProps>(({ ...props }, ref) => (
  <td
    ref={ref}
    className="p-4 align-middle [&:has([role=checkbox])]:pr-0"
    {...props}
  />
))
TableCell.displayName = "TableCell"

export { Table, TableHeader, TableBody, TableRow, TableHead, TableCell }