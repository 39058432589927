import React, { useState, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { EventApi, EventInput } from '@fullcalendar/core';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Event, Vehicle } from '@/types';
import { useEvents } from '@/hooks/useEvents';
import { useVehicles } from '@/hooks/useVehicles';
import { getEventColor } from '@/utils/eventUtils';
import { LoadingSpinner } from '@/components/ui/LoadingSpinner';
import { EventModal } from '@/components/EventModal';
import type { Event as EventModalEvent } from '@/types';
import { CreateEventModal } from '@/components/CreateEventModal';


interface CalendarEvent extends EventInput {
  id: string;
  title: string;
  start: string;
  end: string;
  allDay?: boolean;
  type?: string;
  vehicle_id?: string;
  description?: string;
  location?: string;
  url?: string;
  classNames?: string[];
  editable?: boolean;
  startEditable?: boolean;
  durationEditable?: boolean;
  resourceEditable?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  extendedProps?: {
    [key: string]: any;
  };
}

export default function Calendar() {
  const [selectedVehicle, setSelectedVehicle] = useState<string>('all');
  const { events, isLoading: eventsLoading, mutate: mutateEvents } = useEvents(selectedVehicle);
  const { vehicles, isLoading: vehiclesLoading } = useVehicles();
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const handleEventClick = useCallback((info: { event: EventApi }) => {
    const clickedEvent = events.find(event => 
      (event as unknown as { id?: string | number }).id?.toString() === info.event.id
    );
    
    if (clickedEvent) {
      const safeClickedEvent: CalendarEvent = {
        id: info.event.id,
        title: info.event.title,
        start: info.event.startStr,
        end: info.event.endStr,
        type: (clickedEvent as unknown as { type?: string }).type,
        vehicle_id: (clickedEvent as unknown as { vehicle_id?: string | number }).vehicle_id?.toString(),
      };
      setSelectedEvent(safeClickedEvent);
    }
  }, [events]);

  const closeModal = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const handleDateClick = useCallback((arg: DateClickArg) => {
    setSelectedDate(arg.dateStr);
    setIsCreateModalOpen(true);
  }, []);

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
    setSelectedDate(null);
  }, []);

  const handleEventCreated = useCallback(() => {
    mutateEvents();
  }, [mutateEvents]);

  if (eventsLoading || vehiclesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>Vehicle Availability Calendar</CardTitle>
        </CardHeader>
        <CardContent>
          <Select value={selectedVehicle} onValueChange={setSelectedVehicle}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a vehicle" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Vehicles</SelectItem>
              {vehicles.map((vehicle: Vehicle) => (
                <SelectItem key={vehicle.id} value={vehicle.id}>
                  {vehicle.make} {vehicle.model}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="mt-4">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={events.map((event): EventInput => {
                const typedEvent = event as unknown as {
                  id?: string | number;
                  title?: string;
                  start?: string;
                  end?: string;
                  type?: string;
                  vehicle_id?: string | number;
                };
                return {
                  id: typedEvent.id?.toString() ?? '',
                  title: typedEvent.title ?? '',
                  start: typedEvent.start ?? '',
                  end: typedEvent.end ?? '',
                  type: typedEvent.type ?? '',
                  vehicle_id: typedEvent.vehicle_id?.toString() ?? '',
                  color: getEventColor(typedEvent.type ?? '')
                };
              })}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              height="auto"
            />
          </div>
        </CardContent>
      </Card>
      {selectedEvent && (
        <EventModal 
          event={{
            id: selectedEvent.id,
            title: selectedEvent.title,
            start: selectedEvent.start,
            end: selectedEvent.end,
            type: selectedEvent.type ?? '',
            vehicle_id: selectedEvent.vehicle_id ?? ''
          } as EventModalEvent}
          onClose={closeModal} 
        />
      )}
      {isCreateModalOpen && selectedDate && (
        <CreateEventModal 
          date={selectedDate} 
          onClose={closeCreateModal} 
          onEventCreated={handleEventCreated}
        />
      )}
    </div>
  );
}