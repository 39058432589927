import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Alert, AlertDescription } from '../components/ui/alert';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ChevronDown, ChevronUp } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const statusColors = {
  pending: 'yellow',
  approved: 'green',
  denied: 'red',
  active: 'blue',
  completed: 'gray',
  cancelled: 'orange'
};

interface CustomerProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  // Add other relevant fields
}

const CustomerDashboard: React.FC = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const fetchProfile = async (): Promise<CustomerProfile> => {
    const { data, error } = await supabase
      .from('customers')
      .select('*')
      .eq('id', user?.id)
      .single();
    if (error) throw error;
    return data as CustomerProfile;
  };

  const updateProfile = async (newProfile: Partial<CustomerProfile>): Promise<CustomerProfile> => {
    const { data, error } = await supabase
      .from('customers')
      .update(newProfile)
      .eq('id', user?.id)
      .single();
    if (error) throw error;
    return data as CustomerProfile;
  };

  const { data: profile, isLoading } = useQuery({ queryKey: ['profile', user?.id], queryFn: fetchProfile });

  const updateProfileMutation = useMutation<CustomerProfile, Error, Partial<CustomerProfile>, { previousProfile: CustomerProfile | undefined }>({
    mutationFn: updateProfile,
    onMutate: async (newProfile) => {
      await queryClient.cancelQueries({ queryKey: ['profile', user?.id] });
      const previousProfile = queryClient.getQueryData<CustomerProfile>(['profile', user?.id]);
      queryClient.setQueryData<CustomerProfile>(['profile', user?.id], old => old ? { ...old, ...newProfile } : undefined);
      return { previousProfile };
    },
    onError: (err, newProfile, context) => {
      if (context?.previousProfile) {
        queryClient.setQueryData(['profile', user?.id], context.previousProfile);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['profile', user?.id] });
    },
  });

  const [rentals, setRentals] = useState<{ current: any; upcoming: any[]; past: any[] }>({ current: null, upcoming: [], past: [] });
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showExtensionModal, setShowExtensionModal] = useState(false);
  const [newEndDate, setNewEndDate] = useState<Date | null>(null);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [showUpcoming, setShowUpcoming] = useState(false);
  const [showPast, setShowPast] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchDashboardData();
    }
  }, [user]);

  const fetchDashboardData = async () => {
    setError('');
    try {
      const { data: customerData, error: customerError } = await supabase
        .from('customers')
        .select('*')
        .eq('id', user?.id)
        .single();

      if (customerError) throw customerError;

      setLoyaltyPoints(customerData.loyalty_points);

      const { data: rentalsData, error: rentalsError } = await supabase
        .from('bookings')
        .select('*, vehicles(*)')
        .eq('customer_id', user?.id);

      if (rentalsError) throw rentalsError;

      const currentDate = new Date();
      const current = rentalsData.find((rental: any) => 
        new Date(rental.start_date) <= currentDate && new Date(rental.end_date) >= currentDate
      );
      const upcoming = rentalsData.filter((rental: any) => new Date(rental.start_date) > currentDate);
      const past = rentalsData.filter((rental: any) => new Date(rental.end_date) < currentDate);

      setRentals({ current, upcoming, past });
    } catch (error: any) {
      console.error('Error fetching dashboard data:', error);
      setError(`Failed to fetch dashboard data: ${error.message}`);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (profile) {
      updateProfileMutation.mutate({ ...profile, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (profile) {
      updateProfileMutation.mutate(profile);
    }
  };

  const handleExtensionRequest = async () => {
    if (!newEndDate || !rentals.current) return;

    try {
      const { data, error } = await supabase
        .from('extension_requests')
        .insert({
          booking_id: rentals.current.id,
          requested_end_date: newEndDate.toISOString().split('T')[0],
          status: 'pending'
        });

      if (error) throw error;

      setSuccessMessage('Extension request submitted successfully');
      setShowExtensionModal(false);
      fetchDashboardData();
    } catch (error: any) {
      console.error('Error submitting extension request:', error);
      setError(`Failed to submit extension request: ${error.message}`);
    }
  };

  const fetchAvailableDates = async () => {
    if (!rentals.current) {
      console.error('No current rental found');
      return;
    }

    try {
      const { data: rentalData, error: rentalError } = await supabase
        .from('bookings')
        .select('vehicle_id')
        .eq('id', rentals.current.id)
        .single();

      if (rentalError) throw rentalError;

      const { data: bookingsData, error: bookingsError } = await supabase
        .from('bookings')
        .select('start_date, end_date')
        .eq('vehicle_id', rentalData.vehicle_id)
        .gte('start_date', rentals.current.end_date)
        .order('start_date', { ascending: true });

      if (bookingsError) throw bookingsError;

      const startDate = new Date(rentals.current.end_date);
      startDate.setDate(startDate.getDate() + 1);
      const endDate = bookingsData.length > 0 
        ? new Date(bookingsData[0].start_date) 
        : new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);

      let currentDate = startDate;
      const availableDates: Date[] = [];

      while (currentDate < endDate) {
        availableDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setAvailableDates(availableDates);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };

  const renderRentalCard = (rental: any) => {
    const daysLeft = Math.ceil((new Date(rental.end_date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

    return (
      <Card key={rental.id} className="mb-4">
        <CardContent className="flex flex-col md:flex-row p-4">
          <div className="w-full md:w-1/2 pr-4">
            <p><strong>Vehicle:</strong> {rental.vehicles.make} {rental.vehicles.model}</p>
            <p><strong>Start Date:</strong> {rental.start_date}</p>
            <p><strong>End Date:</strong> {rental.end_date}</p>
            <p><strong>Total Price:</strong> ${rental.total_price}</p>
            <p><strong>Status:</strong> <span style={{color: statusColors[rental.status as keyof typeof statusColors]}}>{rental.status}</span></p>
            {rental.status === 'denied' && (
              <Button onClick={() => alert(`Booking Denied: ${rental.denial_reason}`)} className="mt-2">
                See Denial Reason
              </Button>
            )}
            {rental.status === 'active' && (
              <Button onClick={() => {
                setShowExtensionModal(true);
                fetchAvailableDates();
              }} className="mt-2">
                Extend Rental
              </Button>
            )}
          </div>
          <div className="w-full md:w-1/2 mt-4 md:mt-0 flex justify-center items-center">
            {rental.status === 'active' && (
              <div className="w-32 h-32">
                <CircularProgressbar
                  value={daysLeft}
                  maxValue={Math.ceil((new Date(rental.end_date).getTime() - new Date(rental.start_date).getTime()) / (1000 * 60 * 60 * 24))}
                  text={`${daysLeft} days`}
                  styles={buildStyles({
                    textSize: '16px',
                    pathColor: `rgba(62, 152, 199, ${daysLeft / 100})`,
                    textColor: '#f88',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                  })}
                />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    );
  };

  if (isLoading) {
    return <div>Loading... Please wait.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Customer Dashboard</h1>
        <div>
          <Button onClick={() => setShowEditProfileModal(true)} className="mr-2">Edit Profile</Button>
        </div>
      </div>
      
      {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
      {successMessage && <Alert variant="default"><AlertDescription>{successMessage}</AlertDescription></Alert>}

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Current Rental</CardTitle>
        </CardHeader>
        <CardContent>
          {rentals.current ? renderRentalCard(rentals.current) : <p>You don't have any active rentals at the moment.</p>}
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Loyalty Points</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Your current loyalty points: {loyaltyPoints}</p>
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            Upcoming Rentals
            <Button onClick={() => setShowUpcoming(!showUpcoming)} variant="ghost" size="sm">
              {showUpcoming ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </CardTitle>
        </CardHeader>
        {showUpcoming && (
          <CardContent>
            {rentals.upcoming && rentals.upcoming.length > 0 ? (
              rentals.upcoming.map(renderRentalCard)
            ) : (
              <p>No upcoming rentals.</p>
            )}
          </CardContent>
        )}
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="flex justify-between items-center">
            Past Rentals
            <Button onClick={() => setShowPast(!showPast)} variant="ghost" size="sm">
              {showPast ? <ChevronUp /> : <ChevronDown />}
            </Button>
          </CardTitle>
        </CardHeader>
        {showPast && (
          <CardContent>
            {rentals.past && rentals.past.length > 0 ? (
              rentals.past.map(renderRentalCard)
            ) : (
              <p>No past rentals.</p>
            )}
          </CardContent>
        )}
      </Card>

      {showEditProfileModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  name="firstName"
                  value={profile?.firstName || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  name="lastName"
                  value={profile?.lastName || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={profile?.email || ''}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <Label htmlFor="phoneNumber">Phone Number</Label>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={profile?.phoneNumber || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label htmlFor="street">Street</Label>
                <Input
                  id="street"
                  name="street"
                  value={profile?.street || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  name="city"
                  value={profile?.city || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label htmlFor="state">State</Label>
                <Input
                  id="state"
                  name="state"
                  value={profile?.state || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label htmlFor="zipCode">Zip Code</Label>
                <Input
                  id="zipCode"
                  name="zipCode"
                  value={profile?.zipCode || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <Button type="button" onClick={() => setShowEditProfileModal(false)} variant="outline">Cancel</Button>
                <Button type="submit">Save Changes</Button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showExtensionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Extend Rental</h2>
            <DatePicker
              selected={newEndDate}
              onChange={(date: Date | null) => setNewEndDate(date)}
              minDate={new Date(rentals.current.end_date)}
              includeDates={availableDates}
              className="mb-4 p-2 border rounded"
            />
            <div className="flex justify-end space-x-4">
              <Button onClick={() => setShowExtensionModal(false)} variant="outline">Cancel</Button>
              <Button onClick={handleExtensionRequest}>Submit Request</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;