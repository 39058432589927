import { Event } from '@/types';

export function getEventColor(type: string): string {
  switch (type.toLowerCase()) {
    case 'maintenance':
      return '#FFA500'; // Orange
    case 'rental':
      return '#4CAF50'; // Green
    case 'unavailable':
      return '#F44336'; // Red
    default:
      return '#2196F3'; // Blue (default)
  }
}