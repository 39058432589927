import React from 'react';

interface ContentPageLayoutProps {
  title: string;
  children: React.ReactNode;
}

const ContentPageLayout: React.FC<ContentPageLayoutProps> = ({ title, children }) => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">{title}</h1>
      <div className="prose prose-lg max-w-none bg-white shadow-lg rounded-lg p-8">
        {children}
      </div>
    </div>
  );
};

export default ContentPageLayout;