import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Star, ChevronLeft, ChevronRight, Shield, CreditCard, DollarSign } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../lib/supabaseClient';

const Home = () => {
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const ctaRef = useRef(null);
  const bookButtonRef = useRef(null);

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      [ctaRef, bookButtonRef].forEach(ref => {
        if (ref.current) {
          const { left, top, width, height } = (ref.current as HTMLElement).getBoundingClientRect();
          const x = (e.clientX - left) / width;
          const y = (e.clientY - top) / height;
          (ref.current as HTMLElement).style.setProperty('--mouse-x', x.toString());
          (ref.current as HTMLElement).style.setProperty('--mouse-y', y.toString());
        }
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const fetchVehicles = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .select('*')
        .order('id', { ascending: true });

      if (error) {
        throw error;
      }

      setVehicles(data as any[]);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError('Failed to load vehicles. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const featuredVehicles = [
    { name: "AMG G63 - BLACK", price: 800, image: "/amg-g63-black.jpg" },
    { name: "URUS - BLUE", price: 1200, image: "/urus-blue.jpg" },
    { name: "AUDI R8", price: 800, image: "/audi-r8.jpg" },
    { name: "BMW M8", price: 900, image: "/bmw-m8.jpg" },
    { name: "PORSCHE 911", price: 1100, image: "/porsche-911.jpg" },
    { name: "FERRARI 488", price: 1500, image: "/ferrari-488.jpg" },
    { name: "LAMBORGHINI HURACAN", price: 1400, image: "/lamborghini-huracan.jpg" },
  ];

  const reviews = [
    { 
      text: "Rented the AMG G63 for a weekend trip. RENTCARS NYC made the process incredibly smooth. The G-Wagon was in pristine condition and turned heads everywhere we went!", 
      author: "Michael S." 
    },
    { 
      text: "I booked the Lamborghini Huracan for my anniversary. The team at RENTCARS NYC was professional and the car was absolutely breathtaking. It made our special day unforgettable!", 
      author: "Emma R." 
    },
    { 
      text: "Had the pleasure of driving the Ferrari 488 for a day. RENTCARS NYC's service was top-notch, and the car was a dream to drive. Will definitely be coming back for more exotic rentals!", 
      author: "David L." 
    },
  ];

  const faqs = [
    { question: "Do you offer unlimited mileage?", answer: "Most of our rentals come with unlimited mileage, but please check the specific terms of your rental agreement." },
    { question: "Can I return the car to a different location?", answer: "Yes, we offer one-way rentals, but additional fees may apply. Please contact us for specific arrangements." },
    { question: "What happens if I get a traffic ticket while driving the rental car?", answer: "You are responsible for any traffic violations incurred during your rental period. We'll notify you of any citations we receive and may charge the associated fines to your payment method." },
    { question: "Do you offer any discounts for longer rental periods?", answer: "Yes, we offer discounted rates for weekly and monthly rentals. The longer you rent, the more you save. Contact us for a custom quote for extended rentals." },
  ];

  const nextSlide = () => {
    setCurrentVehicleIndex((prevIndex) => 
      (prevIndex + 1) % featuredVehicles.length
    );
  };

  const prevSlide = () => {
    setCurrentVehicleIndex((prevIndex) => 
      (prevIndex - 1 + featuredVehicles.length) % featuredVehicles.length
    );
  };

  const handleVehicleClick = (index: number) => {
    setCurrentVehicleIndex(index);
  };

  return (
    <div className="min-h-screen text-white">
      {/* Hero Section */}
      <div className="relative z-10 overflow-hidden pt-20 pb-32">
        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center md:flex-row md:justify-between">
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">Luxury Car Rentals in NYC</h1>
              <p className="text-xl mb-6">Experience the thrill of driving premium vehicles</p>
              <Link to="/rental-form">
                <Button size="lg" className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 mb-6">Rent a Car Now</Button>
              </Link>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              {vehicles.length > 0 && (
                <img
                  src={`/api/placeholder/600/400`}
                  alt={`${(vehicles[currentVehicleIndex] as any).make} ${(vehicles[currentVehicleIndex] as any).model}`}
                  className="w-full max-w-md h-auto rounded-lg shadow-lg"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Fleet Section */}
      <div className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-5xl font-bold text-center mb-12 uppercase tracking-wider">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-purple-300 font-montserrat">
              OUR FLEET
            </span>
          </h2>
          <p className="text-center text-gray-300 mb-8">Choose from one of our 7 luxury vehicles</p>
          <div className="relative overflow-hidden h-[400px]">
            <div className="flex justify-center items-center h-full">
              {featuredVehicles.map((vehicle, index) => {
                const distance = (index - currentVehicleIndex + featuredVehicles.length) % featuredVehicles.length - Math.floor(featuredVehicles.length / 2);
                const isActive = distance === 0;
                return (
                  <motion.div
                    key={vehicle.name}
                    className={`absolute transition-all duration-300 cursor-pointer`}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{
                      opacity: isActive ? 1 : 0.5,
                      scale: isActive ? 1 : 0.8,
                      x: distance * 200,
                      zIndex: featuredVehicles.length - Math.abs(distance),
                    }}
                    transition={{ duration: 0.3 }}
                    onClick={() => handleVehicleClick(index)}
                  >
                    <Card className={`w-64 md:w-80 bg-gray-800 bg-opacity-50 text-white overflow-hidden shadow-xl ${isActive ? 'scale-110' : ''}`}>
                      <img 
                        src={vehicle.image}
                        alt={vehicle.name} 
                        className="w-full h-48 object-cover"
                      />
                      <CardContent className="p-4">
                        <h3 className="text-xl font-bold mb-2">{vehicle.name}</h3>
                        <p className="text-2xl font-bold text-blue-400">${vehicle.price}/day</p>
                        <Button className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white">Reserve</Button>
                      </CardContent>
                    </Card>
                  </motion.div>
                );
              })}
            </div>
            <button
              onClick={prevSlide}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
            >
              <ChevronRight size={24} />
            </button>
          </div>
          <div className="text-center mt-8">
            <Button variant="outline" className="border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white">
              View All Vehicles
            </Button>
          </div>
        </div>
      </div>

      {/* Rental Requirements Section */}
      <div className="py-24 relative z-60 overflow-hidden">
        <div className="container mx-auto px-4">
          <p className="text-blue-400 mb-2 text-center font-semibold tracking-wide">SEE YOUR ELIGIBILITY</p>
          <h2 className="text-4xl font-bold mb-12 text-center text-white">Rental Requirements</h2>
          <div className="flex flex-col lg:flex-row items-center justify-between relative">
            <div className="lg:w-1/2 space-y-8 mb-12 lg:mb-0 z-10">
              <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl flex items-start transform transition-all duration-300 hover:scale-105">
                <div className="mr-4 bg-blue-500 p-3 rounded-full">
                  <CreditCard className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="font-bold text-xl mb-2 text-white">Valid Driver's License</h3>
                  <p className="text-gray-300">Must be at least 18 years old with a valid driver's license.</p>
                </div>
              </div>
              <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl flex items-start transform transition-all duration-300 hover:scale-105">
                <div className="mr-4 bg-green-500 p-3 rounded-full">
                  <Shield className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="font-bold text-xl mb-2 text-white">Insurance</h3>
                  <p className="text-gray-300">Must have valid insurance or purchase insurance from us.</p>
                </div>
              </div>
              <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl flex items-start transform transition-all duration-300 hover:scale-105">
                <div className="mr-4 bg-yellow-500 p-3 rounded-full">
                  <DollarSign className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="font-bold text-xl mb-2 text-white">Cash Deposit</h3>
                  <p className="text-gray-300">A cash deposit is required for all rentals.</p>
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <Link to="/rental-form">
                  <button
                    ref={bookButtonRef}
                    className="px-8 py-4 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl text-white"
                    style={{
                      background: 'radial-gradient(circle at calc(var(--mouse-x) * 100%) calc(var(--mouse-y) * 100%), #FFD700, #B8860B)',
                    }}
                  >
                    Book Your Luxury Ride Now
                  </button>
                </Link>
              </div>
            </div>
            <div className="lg:w-1/2 relative z-0">
              <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
              <img src="/luxury-sports-car.jpg" alt="Luxury Sports Car" className="w-full h-auto rounded-lg shadow-2xl relative z-0" />
              <div className="absolute inset-0 bg-gradient-to-r from-transparent to-gray-900 z-20"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Customer Reviews Section */}
      <div className="py-24 relative z-40 overflow-hidden">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">What Our Customers Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {reviews.map((review, index) => (
              <Card key={index} className="p-6 bg-gray-800 bg-opacity-50 text-white shadow-md">
                <p className="mb-4 text-gray-300">"{review.text}"</p>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-white">{review.author}</span>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="text-yellow-400 fill-current" size={20} />
                    ))}
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 relative z-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">Frequently Asked Questions</h2>
          <div className="space-y-8">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-bold mb-2 text-white">{faq.question}</h3>
                <p className="text-gray-300">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="py-16">
        <div className="container mx-auto px-4">
          <div 
            ref={ctaRef}
            className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-3xl overflow-hidden relative"
            style={{
              '--mouse-x': '0.5',
              '--mouse-y': '0.5',
              background: 'radial-gradient(circle at calc(var(--mouse-x) * 100%) calc(var(--mouse-y) * 100%), #4F46E5, #7C3AED)',
              transition: 'background 0.3s',
            } as React.CSSProperties}
          >
            <div className="flex flex-col md:flex-row items-center justify-between p-8 md:p-12">
              <div className="text-white mb-6 md:mb-0">
                <h2 className="text-3xl font-bold mb-4">Unlock Premium Driving Thrills Today</h2>
                <p className="text-xl">Your adventure begins with the turn of a key!</p>
              </div>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <Button variant="outline" className="border-white text-white hover:bg-white hover:text-purple-600">
                  Follow
                </Button>
                <Button className="bg-white text-purple-600 hover:bg-gray-100">Book Now</Button>
              </div>
            </div>
            <img 
              src="/car-silhouette.png" 
              alt="Car Silhouette" 
              className="absolute bottom-0 right-0 w-1/3 md:w-1/4 opacity-20"
              style={{
                transform: 'translate(calc((var(--mouse-x) - 0.5) * 20px), calc((var(--mouse-y) - 0.5) * 20px))',
                transition: 'transform 0.3s',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;