import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Button } from '../components/ui/button';
import { Alert, AlertDescription } from '../components/ui/alert';
import { supabase } from '../lib/supabaseClient';

interface DashboardStats {
  totalBookings: number;
  activeRentals: number;
  upcomingReturns: number;
  revenue: number;
}

interface Booking {
  id: number;
  customers: { first_name: string; last_name: string };
  vehicles: { make: string; model: string };
  start_date: string;
  end_date: string;
}

interface UpcomingReturn {
  id: number;
  customer_name: string;
  vehicle: string;
  end_date: string;
}

const Overview: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats>({
    totalBookings: 0,
    activeRentals: 0,
    upcomingReturns: 0,
    revenue: 0
  });
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [upcomingReturns, setUpcomingReturns] = useState<UpcomingReturn[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchStats();
    fetchBookings();
    fetchUpcomingReturns();
  }, []);

  const fetchStats = async () => {
    try {
      const { data, error } = await supabase.rpc('get_dashboard_stats');
      if (error) throw error;
      setStats(data as DashboardStats);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
      setError('Failed to fetch dashboard stats. Please try again.');
    }
  };

  const fetchBookings = async () => {
    try {
      const { data, error } = await supabase
        .from('bookings')
        .select(`
          *,
          customers (first_name, last_name),
          vehicles (make, model)
        `)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) throw error;
      setBookings(data as Booking[]);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Failed to fetch bookings. Please try again.');
    }
  };

  const fetchUpcomingReturns = async () => {
    try {
      const { data, error } = await supabase.rpc('get_upcoming_returns');
      if (error) throw error;
      setUpcomingReturns(data as UpcomingReturn[]);
    } catch (error) {
      console.error('Error fetching upcoming returns:', error);
      setError('Failed to fetch upcoming returns. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const sendReminder = async (booking: UpcomingReturn) => {
    try {
      const { data, error } = await supabase.rpc('send_reminder', { booking_id: booking.id });
      if (error) throw error;
      alert(data.message);
    } catch (error) {
      console.error('Error sending reminder:', error);
      alert('Failed to send reminder. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Dashboard Overview</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Total Bookings</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{stats.totalBookings}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Active Rentals</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{stats.activeRentals}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Upcoming Returns</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{stats.upcomingReturns}</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Total Revenue</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">${stats.revenue.toFixed(2)}</p>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Recent Bookings</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer</TableHead>
                <TableHead>Vehicle</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {bookings.map((booking) => (
                <TableRow key={booking.id}>
                  <TableCell>{`${booking.customers.first_name} ${booking.customers.last_name}`}</TableCell>
                  <TableCell>{`${booking.vehicles.make} ${booking.vehicles.model}`}</TableCell>
                  <TableCell>{booking.start_date}</TableCell>
                  <TableCell>{booking.end_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    
      <Card>
        <CardHeader>
          <CardTitle>Upcoming Returns</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer</TableHead>
                <TableHead>Vehicle</TableHead>
                <TableHead>Return Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {upcomingReturns.map((return_) => (
                <TableRow key={return_.id}>
                  <TableCell>{return_.customer_name}</TableCell>
                  <TableCell>{return_.vehicle}</TableCell>
                  <TableCell>{return_.end_date}</TableCell>
                  <TableCell>
                    <Button onClick={() => sendReminder(return_)}>Send Reminder</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default Overview;