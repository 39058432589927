import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useVehicles } from '@/hooks/useVehicles';
import { supabase } from '@/lib/supabaseClient';

interface CreateEventModalProps {
  date: string;
  onClose: () => void;
  onEventCreated: () => void;
}

export function CreateEventModal({ date, onClose, onEventCreated }: CreateEventModalProps) {
  const { vehicles } = useVehicles();
  const [title, setTitle] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [eventType, setEventType] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const { data, error } = await supabase
      .from('events')
      .insert([
        {
          title,
          vehicle_id: vehicleId,
          type: eventType,
          start: date,
          end: date // For simplicity, we're setting end date same as start date
        }
      ]);

    if (error) {
      console.error('Error creating event:', error);
      // TODO: Implement error handling, e.g., show an error message to the user
    } else {
      onEventCreated();
      onClose();
    }
  };

  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Event</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <Input type="date" value={date} readOnly />
            <Input 
              placeholder="Event Title" 
              required 
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Select value={vehicleId} onValueChange={setVehicleId}>
              <SelectTrigger>
                <SelectValue placeholder="Select Vehicle" />
              </SelectTrigger>
              <SelectContent>
                {vehicles.map((vehicle) => (
                  <SelectItem key={vehicle.id} value={vehicle.id}>
                    {vehicle.make} {vehicle.model}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select value={eventType} onValueChange={setEventType}>
              <SelectTrigger>
                <SelectValue placeholder="Event Type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="maintenance">Maintenance</SelectItem>
                <SelectItem value="rental">Rental</SelectItem>
                <SelectItem value="other">Other</SelectItem>
              </SelectContent>
            </Select>
            <div className="flex justify-end space-x-2">
              <Button type="button" variant="outline" onClick={onClose}>Cancel</Button>
              <Button type="submit">Create Event</Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}