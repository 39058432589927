import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { format } from 'date-fns';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../lib/supabaseClient';

interface BookingDetails {
  id: string;
  vehicle: string;
  pickupDate: string;
  returnDate: string;
  totalPrice: number;
}

const BookingConfirmation: React.FC = () => {
  const location = useLocation();
  const bookingDetails = location.state?.bookingDetails as BookingDetails | undefined;
  const { user } = useUser();
  const [customerEmail, setCustomerEmail] = useState<string>('');

  useEffect(() => {
    const fetchCustomerEmail = async () => {
      if (user) {
        const { data, error } = await supabase
          .from('customers')
          .select('email')
          .eq('clerk_id', user.id)
          .single();

        if (error) {
          console.error('Error fetching customer email:', error);
        } else if (data) {
          setCustomerEmail(data.email);
        }
      }
    };

    fetchCustomerEmail();
  }, [user]);

  if (!bookingDetails) {
    return <div>No booking details available.</div>;
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy');
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Booking Confirmation</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-green-600 font-semibold mb-4">Your booking has been confirmed!</p>
          <div className="space-y-2">
            <p><strong>Booking ID:</strong> {bookingDetails.id}</p>
            <p><strong>Vehicle:</strong> {bookingDetails.vehicle}</p>
            <p><strong>Pick-up Date:</strong> {formatDate(bookingDetails.pickupDate)}</p>
            <p><strong>Return Date:</strong> {formatDate(bookingDetails.returnDate)}</p>
            <p><strong>Total Price:</strong> ${bookingDetails.totalPrice}</p>
          </div>
          <p className="mt-4">A confirmation email has been sent to {customerEmail || user?.primaryEmailAddress?.emailAddress}.</p>
          <div className="mt-6">
            <Link to="/customer-dashboard">
              <Button>Go to Dashboard</Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BookingConfirmation;