import React from 'react';
import ContentPageLayout from '@/components/ContentPageLayout';

const PrivacyPolicy = () => (
  <ContentPageLayout title="Privacy Policy">
    <p className="mb-4">At AR Royal Rentals, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">Information We Collect</h2>
    <p className="mb-4">We collect information you provide directly to us, such as your name, email address, phone number, and driver's license information when you register for an account or make a reservation.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">How We Use Your Information</h2>
    <p className="mb-4">We use the information we collect to provide, maintain, and improve our services, process your reservations, and communicate with you about your rentals and our services.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">Information Sharing and Security</h2>
    <p className="mb-4">We do not sell or share your personal information with third parties except as necessary to provide our services or as required by law. We implement appropriate technical and organizational measures to protect your personal information.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">Your Rights</h2>
    <p className="mb-4">You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.</p>
    
    <p className="mt-6 text-sm text-gray-600">Last updated: May 1, 2023</p>
  </ContentPageLayout>
);

export default PrivacyPolicy;