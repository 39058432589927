import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Alert, AlertDescription } from '../components/ui/alert';
import { supabase } from '../lib/supabaseClient';

interface RevenueData {
  date: string;
  revenue: number;
}

interface VehicleData {
  make: string;
  model: string;
  rental_count: number;
}

interface UserStats {
  totalUsers: number;
  newUsers: number;
  activeUsers: number;
}

type TimeFrame = 'week' | 'month' | 'year';

const ReportingAnalytics: React.FC = () => {
  const { user } = useUser();
  const [timeFrame, setTimeFrame] = useState<TimeFrame>('month');
  const [revenueData, setRevenueData] = useState<RevenueData[]>([]);
  const [popularVehicles, setPopularVehicles] = useState<VehicleData[]>([]);
  const [userStats, setUserStats] = useState<UserStats>({ totalUsers: 0, newUsers: 0, activeUsers: 0 });
  const [error, setError] = useState<string | null>(null);

  const fetchRevenueData = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_revenue_data', { time_frame: timeFrame });
      if (error) throw error;
      setRevenueData(data as RevenueData[]);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
      setError(`Failed to fetch revenue data: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }, [timeFrame]);

  const fetchPopularVehicles = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_popular_vehicles', { time_frame: timeFrame });
      if (error) throw error;
      setPopularVehicles(data as VehicleData[]);
    } catch (error) {
      console.error('Error fetching popular vehicles:', error);
      setError(`Failed to fetch popular vehicles: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }, [timeFrame]);

  const fetchUserStats = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_user_stats', { time_frame: timeFrame });
      if (error) throw error;
      setUserStats(data as UserStats);
    } catch (error) {
      console.error('Error fetching user stats:', error);
      setError(`Failed to fetch user stats: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }, [timeFrame]);

  useEffect(() => {
    if (user) {
      setError(null);
      fetchRevenueData();
      fetchPopularVehicles();
      fetchUserStats();
    }
  }, [user, timeFrame, fetchRevenueData, fetchPopularVehicles, fetchUserStats]);

  const handleTimeFrameChange = (newTimeFrame: TimeFrame) => {
    setTimeFrame(newTimeFrame);
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Reporting & Analytics</h1>
      
      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="mb-4">
        <Select onValueChange={handleTimeFrameChange} defaultValue={timeFrame}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select time frame" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="week">Last Week</SelectItem>
            <SelectItem value="month">Last Month</SelectItem>
            <SelectItem value="year">Last Year</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Revenue Over Time</CardTitle>
        </CardHeader>
        <CardContent>
          {revenueData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <p>No revenue data available</p>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Most Popular Vehicles</CardTitle>
        </CardHeader>
        <CardContent>
          {popularVehicles.length > 0 ? (
            <ul>
              {popularVehicles.map((vehicle, index) => (
                <li key={index} className="mb-2">
                  {vehicle.make} {vehicle.model} - Rented {vehicle.rental_count} times
                </li>
              ))}
            </ul>
          ) : (
            <p>No popular vehicle data available</p>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>User Statistics</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Total Users: {userStats.totalUsers || 'N/A'}</p>
          <p>New Users: {userStats.newUsers || 'N/A'}</p>
          <p>Active Users: {userStats.activeUsers || 'N/A'}</p>
        </CardContent>
      </Card>
    </div>
  );
};

export default ReportingAnalytics;