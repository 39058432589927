import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react';
import WebFont from 'webfontloader';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error('Missing Clerk publishable key');
}

WebFont.load({
  google: {
    families: ['Orbitron:700', 'Rajdhani:600', 'Montserrat:400,700']
  }
});

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ClerkProvider publishableKey={clerkPubKey}>
        <App />
      </ClerkProvider>
    </React.StrictMode>
  );
}

reportWebVitals();