import React from 'react';
import { Heart } from 'lucide-react';
import { IconBrandInstagram } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-transparent text-white py-6">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h2 className="text-3xl font-bold">AR ROYAL RENTALS</h2>
            <p>LIVE LAUGH LOVE DRIVE FAST</p>
          </div>
          <div className="flex space-x-4">
            <Link to="/instagram" className="text-white hover:text-[#00BFFF]">
              <IconBrandInstagram size={24} />
            </Link>
            <Link to="/x" className="text-white hover:text-[#00BFFF]">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </Link>
          </div>
        </div>
        <div className="mt-6 flex flex-col md:flex-row justify-between items-center">
          <div className="text-sm text-gray-400">
            <p>© {new Date().getFullYear()}, All rights reserved.</p>
            <div className="mt-2">
              <Link to="/privacy-policy" className="hover:text-[#00BFFF]">Privacy Policy</Link>
              <span className="mx-2">|</span>
              <Link to="/terms-of-service" className="hover:text-[#00BFFF]">Terms of Service</Link>
            </div>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <Heart size={16} className="text-red-500 mr-2" />
            <span className="text-sm">
              Designed with Love by{' '}
              <a href="https://moeinteractive.com" target="_blank" rel="noopener noreferrer" className="text-[#00BFFF] hover:underline">
                @moeinteractive
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;