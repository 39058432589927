import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, UserPlus, Mail, Phone, Calendar, Clock, FileText, Paperclip } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '../../components/ui/card';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import { Alert, AlertDescription } from '../../components/ui/alert';
import DeleteConfirmationDialog from '../../components/admin/DeleteConfirmationDialog';
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { Database } from '@/types/supabase';
import { toast } from 'react-toastify';

interface Customer {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  rentals?: {
    current?: {
      vehicle: string;
      start_date: string;
      end_date: string;
      days_left: number;
    };
    upcoming?: Array<{
      vehicle: string;
      start_date: string;
      end_date: string;
    }>;
    past?: Array<{
      vehicle: string;
      start_date: string;
      end_date: string;
    }>;
  };
}

interface CustomerListProps {
  customers: Customer[];
  onSelectCustomer: (customer: Customer) => void;
  onDeleteCustomer: (customer: Customer) => void;
}

function CustomerList({ customers, onSelectCustomer, onDeleteCustomer }: CustomerListProps) {
  return (
    <div className="space-y-2">
      {customers.map((customer) => (
        <Card key={customer.id} className="hover:bg-gray-50 transition-colors">
          <CardContent className="p-4 flex justify-between items-center">
            <div>
              <h3 className="font-medium">{`${customer.first_name} ${customer.last_name}`}</h3>
              <p className="text-sm text-gray-500">{customer.email}</p>
            </div>
            <div className="flex space-x-2">
              <Button 
                onClick={() => onSelectCustomer(customer)}
                size="sm"
                variant="outline"
              >
                View
              </Button>
              <Button 
                onClick={() => onDeleteCustomer(customer)}
                variant="destructive"
                size="sm"
              >
                Delete
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

interface CustomerDetailsProps {
  customer: Customer | null;
}

function CustomerDetails({ customer }: CustomerDetailsProps) {
  if (!customer) return null;

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <FileText className="mr-2" size={20} />
            Customer Information
          </CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex items-center space-x-2">
            <Mail className="text-gray-400" size={16} />
            <span className="text-sm">{customer.email}</span>
          </div>
          <div className="flex items-center space-x-2">
            <Phone className="text-gray-400" size={16} />
            <span className="text-sm">{customer.phone_number}</span>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-semibold flex items-center">
            <Calendar className="mr-2" size={20} />
            Current Rental
          </CardTitle>
        </CardHeader>
        <CardContent>
          {customer.rentals?.current ? (
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-blue-700">
                <Clock className="text-blue-500" size={16} />
                <span className="text-sm font-medium">{`${customer.rentals.current.vehicle} - ${customer.rentals.current.start_date} to ${customer.rentals.current.end_date}`}</span>
              </div>
              <div className="mt-2 flex items-center space-x-2 text-blue-700">
                <span className="text-xs">Days left: {customer.rentals.current.days_left}</span>
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-500">No current rentals</p>
          )}
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <Paperclip className="mr-2" size={20} />
              Upcoming Rentals
            </CardTitle>
          </CardHeader>
          <CardContent>
            {customer.rentals?.upcoming && customer.rentals.upcoming.length > 0 ? (
              <ul className="space-y-2">
                {customer.rentals.upcoming.map((rental, index) => (
                  <li key={index} className="text-sm">
                    {`${rental.vehicle} - ${rental.start_date} to ${rental.end_date}`}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">No upcoming rentals</p>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <Clock className="mr-2" size={20} />
              Past Rentals
            </CardTitle>
          </CardHeader>
          <CardContent>
            {customer.rentals?.past && customer.rentals.past.length > 0 ? (
              <ul className="space-y-2">
                {customer.rentals.past.map((rental, index) => (
                  <li key={index} className="text-sm">
                    {`${rental.vehicle} - ${rental.start_date} to ${rental.end_date}`}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">No past rentals</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default function Customers() {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const supabase = createClientComponentClient<Database>();

  useEffect(() => {
    fetchCustomers();
  }, []);

  async function fetchCustomers() {
    try {
      const { data, error } = await supabase
        .from('customers')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setCustomers(data as Customer[]);
    } catch (err: any) {
      console.error('Error fetching customers:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchCustomerDetails(customerId: string) {
    try {
      const { data, error } = await supabase
        .from('customers')
        .select(`
          *,
          bookings (
            id,
            start_date,
            end_date,
            vehicles (make, model)
          )
        `)
        .eq('id', customerId)
        .single();

      if (error) throw error;
      setSelectedCustomer(data as Customer);
    } catch (err: any) {
      console.error('Error fetching customer details:', err);
      setError(err.message);
    }
  }

  async function confirmDeleteCustomer() {
    if (!customerToDelete) return;

    try {
      const { error } = await supabase
        .from('customers')
        .delete()
        .eq('id', customerToDelete.id);

      if (error) throw error;

      setCustomers(customers.filter(c => c.id !== customerToDelete.id));
      if (selectedCustomer && selectedCustomer.id === customerToDelete.id) {
        setSelectedCustomer(null);
      }
      setIsDeleteDialogOpen(false);
      setCustomerToDelete(null);
      toast.success('Customer deleted successfully');
    } catch (err: any) {
      console.error('Error deleting customer:', err);
      toast.error(`Failed to delete customer: ${err.message}`);
    }
  }

  const filteredCustomers = customers.filter(customer =>
    `${customer.first_name} ${customer.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-4">Customer Management</h1>
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <Input
              type="text"
              placeholder="Search customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 w-full"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <Button className="whitespace-nowrap">
            <UserPlus className="mr-2" size={20} />
            Add Customer
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold">Customer List</CardTitle>
          </CardHeader>
          <CardContent>
            <CustomerList
              customers={filteredCustomers}
              onSelectCustomer={(customer) => {
                fetchCustomerDetails(customer.id);
              }}
              onDeleteCustomer={(customer) => {
                setCustomerToDelete(customer);
                setIsDeleteDialogOpen(true);
              }}
            />
          </CardContent>
        </Card>

        <AnimatePresence>
          {selectedCustomer && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <Card>
                <CardHeader>
                  <CardTitle className="text-xl font-semibold">Customer Details</CardTitle>
                </CardHeader>
                <CardContent>
                  <CustomerDetails customer={selectedCustomer} />
                </CardContent>
              </Card>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={confirmDeleteCustomer}
        userName={customerToDelete ? `${customerToDelete.first_name} ${customerToDelete.last_name}` : ''}
      />
    </div>
  );
}