import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { supabase } from '../lib/supabaseClient';

interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  loyalty_points: number;
  total_spent: number;
}

const LoyaltyProgram = () => {
  const [topCustomers, setTopCustomers] = useState<Customer[]>([]);
  const [pointsPerDollar, setPointsPerDollar] = useState(0);
  const [discountPerPoint, setDiscountPerPoint] = useState(0);

  useEffect(() => {
    fetchTopCustomers();
    fetchLoyaltySettings();
  }, []);

  const fetchTopCustomers = async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_top_customers')
        .limit(10);

      if (error) throw error;
      setTopCustomers(data);
    } catch (error) {
      console.error('Error fetching top customers:', error);
    }
  };
  
  const fetchLoyaltySettings = async () => {
    try {
      const { data, error } = await supabase
        .from('loyalty_settings')
        .select('*')
        .single();

      if (error) throw error;
      setPointsPerDollar(data.points_per_dollar);
      setDiscountPerPoint(data.discount_per_point);
    } catch (error) {
      console.error('Error fetching loyalty settings:', error);
    }
  };
  
  const handleUpdateSettings = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('loyalty_settings')
        .upsert({ 
          id: 1, // Assuming there's only one row in the loyalty_settings table
          points_per_dollar: pointsPerDollar, 
          discount_per_point: discountPerPoint 
        });

      if (error) throw error;
      alert('Loyalty settings updated successfully');
    } catch (error) {
      console.error('Error updating loyalty settings:', error);
      if (error instanceof Error) {
        alert('Failed to update loyalty settings: ' + error.message);
      } else {
        alert('Failed to update loyalty settings');
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Loyalty Program Settings</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleUpdateSettings} className="space-y-4">
            <div>
              <Label htmlFor="pointsPerDollar">Points Earned Per Dollar Spent</Label>
              <Input
                id="pointsPerDollar"
                type="number"
                step="0.01"
                value={pointsPerDollar}
                onChange={(e) => setPointsPerDollar(Number(e.target.value))}
                required
              />
            </div>
            <div>
              <Label htmlFor="discountPerPoint">Discount Amount Per Point Redeemed ($)</Label>
              <Input
                id="discountPerPoint"
                type="number"
                step="0.01"
                value={discountPerPoint}
                onChange={(e) => setDiscountPerPoint(Number(e.target.value))}
                required
              />
            </div>
            <Button type="submit">Update Settings</Button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Top Loyalty Program Members</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Loyalty Points</TableHead>
                <TableHead>Total Spent</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {topCustomers.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>{`${customer.first_name} ${customer.last_name}`}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.loyalty_points}</TableCell>
                  <TableCell>${customer.total_spent.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoyaltyProgram;