import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from './ui/button';
import { Menu, X } from 'lucide-react';
import { useUser, useClerk, UserButton } from '@clerk/clerk-react';
import './Header.css';

const Logo: React.FC = () => (
  <div className="flex flex-col items-start">
    <span className="text-2xl font-bold tracking-wider text-white font-montserrat">RENTCARS.NYC</span>
    <span className="text-xs text-gray-300 font-montserrat">AR ROYAL RENTAL CORP</span>
  </div>
);

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn, user } = useUser();
  const { signOut } = useClerk();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    await signOut();
    navigate('/');
    setIsMenuOpen(false);
  };

  const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
    const isActive = location.pathname === to;
    return (
      <Link 
        to={to} 
        className={`text-white hover:text-tesla-red transition-colors ${isActive ? 'font-bold' : ''}`}
        onClick={() => setIsMenuOpen(false)}
      >
        {children}
      </Link>
    );
  };

  const navItems = (
    <>
      <li><NavLink to="/">Home</NavLink></li>
      <li><NavLink to="/fleet">Fleet</NavLink></li>
      <li><NavLink to="/contact-us">Contact Us</NavLink></li>
      
      {!isSignedIn && (
        <li><NavLink to="/sign-in">Login / Register</NavLink></li>
      )}

      {isSignedIn && (
        <>
          <li><NavLink to="/customer-dashboard">Dashboard</NavLink></li>
          <li><NavLink to="/rental-form">Rent a Car</NavLink></li>
        </>
      )}

      {isSignedIn && user?.publicMetadata?.role === 'admin' && (
        <li><NavLink to="/admin">Admin Dashboard</NavLink></li>
      )}

      {isSignedIn && (
        <li>
          <UserButton afterSignOutUrl="/" />
        </li>
      )}
    </>
  );

  return (
    <header className={`header fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-black bg-opacity-75 shadow-lg' : 'bg-transparent'}`}>
      <nav className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <Logo />
          </Link>
          {isMobile ? (
            <div>
              <Button 
                onClick={() => setIsMenuOpen(!isMenuOpen)} 
                variant="ghost" 
                className="text-white hover:text-tesla-red"
                size="sm"
              >
                {isMenuOpen ? <X /> : <Menu />}
              </Button>
            </div>
          ) : (
            <ul className="flex space-x-6 items-center">
              {navItems}
            </ul>
          )}
        </div>
        {isMobile && isMenuOpen && (
          <div className={`mt-4 ${isScrolled ? 'bg-black bg-opacity-75' : 'bg-transparent'} p-4 rounded-lg`}>
            <ul className="space-y-4 text-center text-lg">
              {navItems}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;