import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { Alert, AlertDescription } from '../components/ui/alert';
import { supabase } from '../lib/supabaseClient';

interface ExtensionRequest {
  id: string;
  bookings: {
    users: {
      first_name: string;
      last_name: string;
    };
    vehicles: {
      make: string;
      model: string;
    };
    end_date: string;
  };
  requested_end_date: string;
  status: string;
}

const ExtensionRequests: React.FC = () => {
  const [requests, setRequests] = useState<ExtensionRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchExtensionRequests();
  }, []);

  const fetchExtensionRequests = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('extension_requests')
        .select(`
          *,
          bookings (
            *,
            users (first_name, last_name),
            vehicles (make, model)
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data as ExtensionRequest[]);
    } catch (error) {
      console.error('Error fetching extension requests:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleExtensionAction = async (requestId: string, action: 'approve' | 'deny') => {
    try {
      const { error } = await supabase
        .from('extension_requests')
        .update({ status: action })
        .eq('id', requestId);

      if (error) throw error;
      await fetchExtensionRequests(); // Refresh the list
    } catch (error) {
      console.error('Error processing extension request:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Extension Requests</CardTitle>
      </CardHeader>
      <CardContent>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {requests.length === 0 ? (
          <p>No extension requests available.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer</TableHead>
                <TableHead>Vehicle</TableHead>
                <TableHead>Current End Date</TableHead>
                <TableHead>Requested End Date</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {requests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{`${request.bookings.users.first_name} ${request.bookings.users.last_name}`}</TableCell>
                  <TableCell>{`${request.bookings.vehicles.make} ${request.bookings.vehicles.model}`}</TableCell>
                  <TableCell>{new Date(request.bookings.end_date).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(request.requested_end_date).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleExtensionAction(request.id, 'approve')} className="mr-2">Approve</Button>
                    <Button onClick={() => handleExtensionAction(request.id, 'deny')} variant="destructive">Deny</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default ExtensionRequests;
