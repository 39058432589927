import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { MapPin, Phone, Clock } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';

interface FormData {
  name: string;
  email: string;
  message: string;
}

interface SubmitMessage {
  type: 'success' | 'error';
  text: string;
}

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage(null);

    try {
      const { error } = await supabase
        .from('contact_messages')
        .insert([formData]);

      if (error) throw error;

      setSubmitMessage({ type: 'success', text: 'Your message has been sent successfully. We will get back to you soon!' });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage({ type: 'error', text: 'An error occurred while submitting the form. Please try again later.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const pickupLocations = [
    {
      name: "STATEN ISLAND",
      address: '123 Main St',
      city: 'Staten Island',
      state: 'NY',
      zip: '10001'
    },
    {
      name: "LITTLE FERRY",
      address: '375 Liberty St',
      city: 'Little Ferry',
      state: 'NJ',
      zip: '07643'
    }
  ];

  const faqs = [
    {
      question: "What documents do I need to rent a car?",
      answer: "You'll need a valid driver's license, a major credit card in your name, and proof of insurance. International renters may need additional documentation."
    },
    {
      question: "Is there an age requirement to rent a car?",
      answer: "Yes, the minimum age to rent a car is typically 21, but there may be additional fees for drivers under 25."
    },
    {
      question: "Do you offer unlimited mileage?",
      answer: "Most of our rentals come with unlimited mileage, but please check the specific terms of your rental agreement."
    },
    {
      question: "Can I return the car to a different location?",
      answer: "Yes, we offer one-way rentals, but additional fees may apply. Please contact us for specific arrangements."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-5xl font-bold text-center mb-8 uppercase tracking-wider">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 font-montserrat">
          CONTACT US
        </span>
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl font-bold">We'd Love to Hear from You</CardTitle>
          </CardHeader>
          <CardContent>
            {submitMessage && (
              <Alert variant={submitMessage.type === 'success' ? 'default' : 'destructive'} className="mb-4">
                <AlertDescription>{submitMessage.text}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <Label htmlFor="name">Name</Label>
                <Input 
                  type="text" 
                  id="name" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="email">Email</Label>
                <Input 
                  type="email" 
                  id="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <div>
                <Label htmlFor="message">Message</Label>
                <Textarea 
                  id="message" 
                  name="message" 
                  value={formData.message} 
                  onChange={handleInputChange} 
                  required 
                />
              </div>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </Button>
            </form>
          </CardContent>
        </Card>

        <div className="space-y-4">
          <Card>
            <CardContent>
              <p className="text-2xl font-bold mb-2">1-800-RENT-CAR</p>
              <p className="flex items-center">
                <Clock className="mr-2" /> Available 24/7
              </p>
            </CardContent>
          </Card>

          {pickupLocations.map((location, index) => (
            <Card key={index}>
              <CardContent className="p-4">
                <h3 className="text-2xl font-bold mb-2">{location.address}</h3>
                <p className="text-lg mb-2">{location.city}, {location.state} {location.zip}</p>
                <div className="flex items-center text-lg font-semibold">
                  <MapPin className="mr-2" /> {location.name}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-3xl font-bold text-center">FREQUENTLY ASKED QUESTIONS</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index}>
                <h3 className="font-bold text-xl">{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ContactUs;