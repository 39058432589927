import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { supabase } from '../lib/supabaseClient';

interface Vehicle {
  id: number;
  make: string;
  model: string;
  year: number;
  daily_rate: number;
}

const Fleet: React.FC = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchVehicles = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .select('*')
        .order('id', { ascending: true });

      if (error) {
        throw error;
      }

      setVehicles(data || []);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError('Failed to load vehicles. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      {isLoading ? (
        <div className="text-center text-2xl mt-8">Loading...</div>
      ) : error ? (
        <div className="text-red-500 text-center text-2xl mt-8">{error}</div>
      ) : vehicles.length === 0 ? (
        <div className="text-center text-2xl mt-8">No vehicles available at the moment.</div>
      ) : (
        <>
          <h1 className="text-5xl font-bold text-center mb-8 uppercase tracking-wider">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 font-montserrat">
              OUR FLEET
            </span>
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center">
            {vehicles.map(vehicle => (
              <Card 
                key={vehicle.id} 
                className="w-full max-w-sm overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl"
              >
                <div className="h-48 bg-gray-200 relative">
                  <img 
                    src={`/api/placeholder/400/300`} 
                    alt={`${vehicle.make} ${vehicle.model}`} 
                    className="w-full h-full object-cover"
                  />
                </div>
                <CardContent className="bg-gradient-to-br from-gray-900 to-gray-800 text-white p-4">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-3xl font-bold font-montserrat">{vehicle.year}</span>
                    <span className="text-2xl font-light text-gray-400">|</span>
                    <div className="text-left">
                      <h2 className="text-xl font-bold font-montserrat uppercase">{vehicle.make}</h2>
                      <p className="text-lg font-montserrat">{vehicle.model}</p>
                    </div>
                  </div>
                  <p className="text-3xl font-bold text-yellow-400 text-center mt-2">${vehicle.daily_rate}/day</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Fleet;