import React from 'react';
import ContentPageLayout from '@/components/ContentPageLayout';

const TermsOfService = () => (
  <ContentPageLayout title="Terms of Service">
    <p className="mb-4">Welcome to AR Royal Rentals. By using our services, you agree to comply with and be bound by the following terms and conditions.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">1. Rental Agreement</h2>
    <p className="mb-4">When you rent a vehicle from us, you agree to the terms of our Rental Agreement, which will be provided to you at the time of rental.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">2. Eligibility</h2>
    <p className="mb-4">To rent a vehicle, you must be at least 25 years old and possess a valid driver's license.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">3. Payment</h2>
    <p className="mb-4">You agree to pay all charges specified in the Rental Agreement, including rental fees, insurance, and any additional services you select.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">4. Vehicle Use</h2>
    <p className="mb-4">You agree to use the vehicle only for personal or business use and in accordance with all applicable laws and regulations.</p>
    
    <h2 className="text-2xl font-semibold mt-6 mb-3">5. Liability</h2>
    <p className="mb-4">You are responsible for any loss or damage to the vehicle during your rental period, subject to the terms of your selected insurance coverage.</p>
    
    <p className="mt-6 text-sm text-gray-600">Last updated: May 1, 2023</p>
  </ContentPageLayout>
);

export default TermsOfService;