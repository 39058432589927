import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, addDays, subYears, isAfter, differenceInDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "../styles/custom-datepicker.css";
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Alert, AlertDescription } from '../components/ui/alert';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../lib/supabaseClient';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
  'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
  'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  driversLicense: string;
  licenseState: string;
  dateOfBirth: Date | null;
  licenseExpiration: Date | null;
  selectedVehicle: string;
  pickupDate: Date | null;
  returnDate: Date | null;
  ezPass: boolean;
  insurance: boolean;
  ownInsurance: boolean;
  existingInsurance: {
    provider: string;
    policyNumber: string;
    expirationDate: Date | null;
  };
  depositMethod: string;
  agreeToTerms: boolean;
}

interface Errors {
  [key: string]: string | undefined;
}

interface Vehicle {
  id: number;
  year: number;
  make: string;
  model: string;
  daily_rate: number;
}

interface CustomerData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  street: string;
  city: string;
  state: string;
  zip_code: string;
}

const RentalForm: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchVehicles = async (): Promise<Vehicle[]> => {
    const { data, error } = await supabase
      .from('vehicles')
      .select('*')
      .eq('status', 'active');

    if (error) throw error;
    return data;
  };

  const fetchCustomerData = async (): Promise<CustomerData> => {
    if (!user) throw new Error('User not authenticated');
    const { data, error } = await supabase
      .from('customers')
      .select('*')
      .eq('user_id', user.id)
      .single();

    if (error) throw error;
    return data;
  };

  const { data: vehiclesData, isLoading: isVehiclesLoading } = useQuery<Vehicle[], Error>({
    queryKey: ['vehicles'],
    queryFn: fetchVehicles
  });

  const { data: customerData, isLoading: isCustomerLoading } = useQuery<CustomerData, Error>({
    queryKey: ['customer', user?.id],
    queryFn: fetchCustomerData,
    enabled: !!user
  });

  const updateCustomer = async (customerData: Partial<CustomerData>) => {
    if (!user) throw new Error('User not authenticated');
    const { data, error } = await supabase
      .from('customers')
      .update(customerData)
      .eq('user_id', user.id);

    if (error) throw error;
    return data;
  };

  const updateCustomerMutation = useMutation({
    mutationFn: updateCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer', user?.id] });
    },
  });

  useEffect(() => {
    const subscription = supabase
      .channel('vehicles')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'vehicles' }, () => {
        queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [queryClient]);

  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    driversLicense: '',
    licenseState: '',
    dateOfBirth: null,
    licenseExpiration: null,
    selectedVehicle: '',
    pickupDate: null,
    returnDate: null,
    ezPass: false,
    insurance: false,
    ownInsurance: false,
    existingInsurance: { provider: '', policyNumber: '', expirationDate: null },
    depositMethod: '',
    agreeToTerms: false,
  });

  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (user && customerData) {
      setFormData(prevData => ({
        ...prevData,
        firstName: customerData.first_name || '',
        lastName: customerData.last_name || '',
        email: customerData.email || '',
        phoneNumber: customerData.phone_number || '',
        street: customerData.street || '',
        city: customerData.city || '',
        state: customerData.state || '',
        zipCode: customerData.zip_code || '',
      }));
      setIsLoading(false);
    }
  }, [user, customerData]);

  useEffect(() => {
    if (formData.selectedVehicle) {
      fetchAvailability(formData.selectedVehicle);
      setFormData(prev => ({
        ...prev,
        pickupDate: null,
        returnDate: null
      }));
    }
  }, [formData.selectedVehicle]);

  const fetchAvailability = async (vehicleId: string) => {
    try {
      const startDate = format(new Date(), 'yyyy-MM-dd');
      const endDate = format(addDays(new Date(), 30), 'yyyy-MM-dd');
      const { data, error } = await supabase.rpc('get_vehicle_availability', {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate
      });

      if (error) throw error;

      const unavailable = data
        .filter((day: { available: boolean }) => !day.available)
        .map((day: { date: string }) => new Date(day.date));
      setUnavailableDates(unavailable);
    } catch (error) {
      console.error('Error fetching availability:', error);
      setErrors(prev => ({ ...prev, availability: 'Failed to load availability. Please try again.' }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (name === 'phoneNumber') {
      const numericValue = value.replace(/\D/g, '');
      setFormData(prev => ({ ...prev, [name]: numericValue }));
    } else if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      if (name === 'insurance' || name === 'ownInsurance') {
        setFormData(prev => ({
          ...prev,
          insurance: name === 'insurance' ? checked : false,
          ownInsurance: name === 'ownInsurance' ? checked : false
        }));
      } else {
        setFormData(prev => ({ ...prev, [name]: checked }));
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    setErrors(prev => ({ ...prev, [name]: undefined }));
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData(prev => ({ ...prev, [name]: date }));
    setErrors(prev => ({ ...prev, [name]: undefined }));
  };

  const validateForm = (): boolean => {
    const newErrors: Errors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = 'Invalid email format';
    if (!formData.phoneNumber.trim()) newErrors.phoneNumber = 'Phone number is required';
    else if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'Invalid phone number format';
    if (!formData.street.trim()) newErrors.street = 'Street address is required';
    if (!formData.city.trim()) newErrors.city = 'City is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.zipCode.trim()) newErrors.zipCode = 'Zip code is required';
    if (!formData.driversLicense.trim()) newErrors.driversLicense = 'Driver\'s license number is required';
    if (!formData.licenseState) newErrors.licenseState = 'License state is required';
    if (!formData.dateOfBirth) newErrors.dateOfBirth = 'Date of birth is required';
    else {
      const eighteenYearsAgo = subYears(new Date(), 18);
      if (isAfter(formData.dateOfBirth, eighteenYearsAgo)) newErrors.dateOfBirth = 'You must be at least 18 years old';
    }
    if (!formData.licenseExpiration) newErrors.licenseExpiration = 'License expiration date is required';
    else if (isAfter(new Date(), formData.licenseExpiration)) newErrors.licenseExpiration = 'Your license has expired';
    if (!formData.selectedVehicle) newErrors.selectedVehicle = 'Vehicle selection is required';
    if (!formData.pickupDate) newErrors.pickupDate = 'Pickup date is required';
    if (!formData.returnDate) newErrors.returnDate = 'Return date is required';
    if (!formData.depositMethod) newErrors.depositMethod = 'Deposit method is required';
    if (!formData.agreeToTerms) newErrors.agreeToTerms = 'You must agree to the terms and conditions';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    setIsSubmitting(true);
    try {
      const totalPrice = calculateTotalPrice(formData);
      const { data, error } = await supabase
        .from('bookings')
        .insert([
          {
            customer_id: user?.id,
            vehicle_id: formData.selectedVehicle,
            start_date: format(formData.pickupDate!, 'yyyy-MM-dd'),
            end_date: format(formData.returnDate!, 'yyyy-MM-dd'),
            total_price: totalPrice,
            status: 'pending',
            ez_pass: formData.ezPass,
            insurance: formData.insurance,
            own_insurance: formData.ownInsurance,
            deposit_method: formData.depositMethod,
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            phone_number: formData.phoneNumber,
            street: formData.street,
            city: formData.city,
            state: formData.state,
            zip_code: formData.zipCode,
            drivers_license: formData.driversLicense,
            license_state: formData.licenseState,
            date_of_birth: format(formData.dateOfBirth!, 'yyyy-MM-dd'),
            license_expiration: format(formData.licenseExpiration!, 'yyyy-MM-dd'),
            own_insurance_provider: formData.ownInsurance ? formData.existingInsurance.provider : null,
            own_insurance_policy_number: formData.ownInsurance ? formData.existingInsurance.policyNumber : null,
            own_insurance_expiration: formData.ownInsurance && formData.existingInsurance.expirationDate ? format(formData.existingInsurance.expirationDate, 'yyyy-MM-dd') : null,
          }
        ])
        .select()
        .single();
  
      if (error) throw error;
  
      navigate('/booking-confirmation', { 
        state: { 
          bookingDetails: {
            id: data.id,
            vehicle: formData.selectedVehicle,
            pickupDate: formData.pickupDate?.toISOString(),
            returnDate: formData.returnDate?.toISOString(),
            totalPrice: data.total_price,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            phoneNumber: data.phone_number,
            ezPass: data.ez_pass,
            insurance: data.insurance,
            ownInsurance: data.own_insurance,
            depositMethod: data.deposit_method,
          } 
        }
      });
    } catch (error) {
      console.error('Error:', error);
      setErrors(prev => ({ ...prev, general: (error as Error).message || 'An error occurred while processing your booking. Please try again.' }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateTotalPrice = (formData: FormData): number => {
    const selectedVehicle = vehiclesData?.find(v => v.id.toString() === formData.selectedVehicle);
    if (!selectedVehicle || !formData.pickupDate || !formData.returnDate) {
      return 0;
    }

    const rentalDays = differenceInDays(formData.returnDate, formData.pickupDate) + 1;
    let totalPrice = selectedVehicle.daily_rate * rentalDays;

    if (formData.ezPass) {
      totalPrice += 15;
    }

    if (formData.insurance) {
      totalPrice += 25 * rentalDays;
    }

    if (rentalDays >= 7) {
      totalPrice *= 0.9;
    } else if (rentalDays >= 3) {
      totalPrice *= 0.95;
    }

    return Math.round(totalPrice * 100) / 100;
  };

  return (
    <Card className="max-w-4xl mx-auto px-4 py-8">
      <CardHeader>
        <CardTitle className="text-3xl font-bold">Rental Agreement Form</CardTitle>
      </CardHeader>
      <CardContent>
        {errors.general && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{errors.general}</AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Personal Information */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Personal Information</h2>
            <p className="text-sm text-gray-500">This information is pre-filled from your account. You can update it if needed.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="firstName">First Name:</Label>
                <Input
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.firstName ? "true" : "false"}
                />
                {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
              </div>
              <div>
                <Label htmlFor="lastName">Last Name:</Label>
                <Input
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.lastName ? "true" : "false"}
                />
                {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
              </div>
              <div>
                <Label htmlFor="email">Email:</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
              <div>
                <Label htmlFor="phoneNumber">Phone Number:</Label>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                  maxLength={10}
                  placeholder="Please enter your phone number here"
                  aria-invalid={errors.phoneNumber ? "true" : "false"}
                />
                {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>}
              </div>
            </div>
          </div>

          {/* Address */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Address</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-2">
                <Label htmlFor="street">Street:</Label>
                <Input
                  id="street"
                  name="street"
                  value={formData.street}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.street ? "true" : "false"}
                />
                {errors.street && <p className="text-red-500 text-sm mt-1">{errors.street}</p>}
              </div>
              <div>
                <Label htmlFor="city">City:</Label>
                <Input
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.city ? "true" : "false"}
                />
                {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
              </div>
              <div>
                <Label htmlFor="state">State:</Label>
                <select
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  aria-invalid={errors.state ? "true" : "false"}
                >
                  <option value="">Please select your state</option>
                  {states.map((state) => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
                {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
              </div>
              <div>
                <Label htmlFor="zipCode">Zip Code:</Label>
                <Input
                  id="zipCode"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.zipCode ? "true" : "false"}
                />
                {errors.zipCode && <p className="text-red-500 text-sm mt-1">{errors.zipCode}</p>}
              </div>
            </div>
          </div>

          {/* Driver's License Information */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Driver's License Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="driversLicense">Driver's License Number:</Label>
                <Input
                  id="driversLicense"
                  name="driversLicense"
                  value={formData.driversLicense}
                  onChange={handleInputChange}
                  required
                  aria-invalid={errors.driversLicense ? "true" : "false"}
                />
                {errors.driversLicense && <p className="text-red-500 text-sm mt-1">{errors.driversLicense}</p>}
              </div>
              <div>
                <Label htmlFor="licenseState">License State:</Label>
                <select
                  id="licenseState"
                  name="licenseState"
                  value={formData.licenseState}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  aria-invalid={errors.licenseState ? "true" : "false"}
                >
                  <option value="">Please select your license state</option>
                  {states.map((state) => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
                {errors.licenseState && <p className="text-red-500 text-sm mt-1">{errors.licenseState}</p>}
              </div>
              <div>
                <Label htmlFor="dateOfBirth">Date of Birth:</Label>
                <DatePicker
                  id="dateOfBirth"
                  selected={formData.dateOfBirth}
                  onChange={(date: Date | null) => handleDateChange(date, 'dateOfBirth')}
                  maxDate={subYears(new Date(), 18)}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  className="w-full p-2 border rounded"
                  required
                  aria-invalid={errors.dateOfBirth ? "true" : "false"}
                />
                {errors.dateOfBirth && <p className="text-red-500 text-sm mt-1">{errors.dateOfBirth}</p>}
              </div>
              <div>
                <Label htmlFor="licenseExpiration">License Expiration Date:</Label>
                <DatePicker
                  id="licenseExpiration"
                  selected={formData.licenseExpiration}
                  onChange={(date: Date | null) => handleDateChange(date, 'licenseExpiration')}
                  minDate={new Date()}
                  className="w-full p-2 border rounded"
                  required
                  aria-invalid={errors.licenseExpiration ? "true" : "false"}
                />
                {errors.licenseExpiration && <p className="text-red-500 text-sm mt-1">{errors.licenseExpiration}</p>}
              </div>
            </div>
          </div>

          {/* Vehicle Selection */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Vehicle Selection</h2>
            <div>
              <Label htmlFor="selectedVehicle">Select Vehicle:</Label>
              {isLoading ? (
                <p>Loading vehicles...</p>
              ) : vehiclesData && vehiclesData.length > 0 ? (
                <select
                  id="selectedVehicle"
                  name="selectedVehicle"
                  value={formData.selectedVehicle}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  aria-invalid={errors.selectedVehicle ? "true" : "false"}
                >
                  <option value="">Select a vehicle</option>
                  {vehiclesData.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id.toString()}>
                      {vehicle.year} {vehicle.make} {vehicle.model} - ${vehicle.daily_rate}/day
                    </option>
                  ))}
                </select>
              ) : (
                <p>No vehicles available at the moment.</p>
              )}
              {errors.selectedVehicle && <p className="text-red-500 text-sm mt-1">{errors.selectedVehicle}</p>}
            </div>

            {formData.selectedVehicle && (
              <div className="space-y-4">
                <div>
                  <Label htmlFor="pickupDate">Pickup Date:</Label>
                  <DatePicker
                    id="pickupDate"
                    selected={formData.pickupDate}
                    onChange={(date: Date | null) => handleDateChange(date, 'pickupDate')}
                    minDate={new Date()}
                    excludeDates={unavailableDates}
                    className="w-full p-2 border rounded custom-datepicker"
                    required
                    aria-invalid={errors.pickupDate ? "true" : "false"}
                  />
                  {errors.pickupDate && <p className="text-red-500 text-sm mt-1">{errors.pickupDate}</p>}
                </div>
                <div>
                  <Label htmlFor="returnDate">Return Date:</Label>
                  <DatePicker
                    id="returnDate"
                    selected={formData.returnDate}
                    onChange={(date: Date | null) => handleDateChange(date, 'returnDate')}
                    minDate={formData.pickupDate || new Date()}
                    excludeDates={unavailableDates}
                    className="w-full p-2 border rounded custom-datepicker"
                    required
                    aria-invalid={errors.returnDate ? "true" : "false"}
                  />
                  {errors.returnDate && <p className="text-red-500 text-sm mt-1">{errors.returnDate}</p>}
                </div>
              </div>
            )}
          </div>

          {/* Additional Options */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Additional Options</h2>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="ezPass"
                name="ezPass"
                checked={formData.ezPass}
                onChange={handleInputChange}
              />
              <Label htmlFor="ezPass">Include EZ Pass</Label>
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="insurance"
                name="insurance"
                checked={formData.insurance}
                onChange={handleInputChange}
              />
              <Label htmlFor="insurance">Include Insurance</Label>
            </div>

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="ownInsurance"
                name="ownInsurance"
                checked={formData.ownInsurance}
                onChange={handleInputChange}
              />
              <Label htmlFor="ownInsurance">I have my own insurance</Label>
            </div>

            {formData.ownInsurance && (
              <div className="space-y-4">
                <div>
                  <Label htmlFor="existingInsurance.provider">Insurance Provider:</Label>
                  <Input
                    id="existingInsurance.provider"
                    name="existingInsurance.provider"
                    value={formData.existingInsurance.provider}
                    onChange={(e) => setFormData(prev => ({ ...prev, existingInsurance: { ...prev.existingInsurance, provider: e.target.value } }))}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="existingInsurance.policyNumber">Policy Number:</Label>
                  <Input
                    id="existingInsurance.policyNumber"
                    name="existingInsurance.policyNumber"
                    value={formData.existingInsurance.policyNumber}
                    onChange={(e) => setFormData(prev => ({ ...prev, existingInsurance: { ...prev.existingInsurance, policyNumber: e.target.value } }))}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="existingInsurance.expirationDate">Insurance Expiration Date:</Label>
                  <DatePicker
                    id="existingInsurance.expirationDate"
                    selected={formData.existingInsurance.expirationDate}
                    onChange={(date: Date | null) => setFormData(prev => ({ ...prev, existingInsurance: { ...prev.existingInsurance, expirationDate: date } }))}
                    minDate={new Date()}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>
            )}
          </div>

          {/* Deposit Method */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Deposit Method</h2>
            <div>
              <Label htmlFor="depositMethod">Select Deposit Method:</Label>
              <select
                id="depositMethod"
                name="depositMethod"
                value={formData.depositMethod}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
                aria-invalid={errors.depositMethod ? "true" : "false"}
              >
                <option value="">Select deposit method</option>
                <option value="cash">Cash</option>
                <option value="cashapp">Cash App</option>
                <option value="zelle">Zelle</option>
              </select>
              {errors.depositMethod && <p className="text-red-500 text-sm mt-1">{errors.depositMethod}</p>}
            </div>
          </div>

          {/* Terms and Conditions */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">Terms and Conditions</h2>
            <div className="h-40 overflow-y-auto border p-4 rounded">
              <p>
                By agreeing to these terms and conditions, you acknowledge and agree to the following:

                1. You must be at least 18 years old and possess a valid driver's license to rent a vehicle.
                2. You agree to return the vehicle in the same condition as when it was rented, except for normal wear and tear.
                3. You will not use the vehicle for any illegal purposes or in violation of any laws or regulations.
                4. You will not sublet the vehicle or allow any unauthorized drivers to operate it.
                5. You are responsible for all tolls, parking fees, and traffic violations incurred during the rental period.
                6. You agree that any tickets, tolls, fines, or damages may be deducted from your deposit.
                7. You agree to pay for any damage to the vehicle caused by your negligence or misuse.
                8. You will maintain the required minimum auto insurance coverage as mandated by the state of New Jersey or New York, whichever is applicable.
                9. You understand that smoking is prohibited in the vehicle, and a cleaning fee will be deducted from your deposit if evidence of smoking is found.
                10. You agree to use the vehicle only within the continental United States unless otherwise authorized in writing.
                11. You will report any accidents or damage to the rental company immediately.
                12. You understand that early returns do not result in refunds and extensions must be approved in advance.
                13. You agree to pay all charges associated with the rental, including optional services and equipment.
                14. You authorize the rental company to use your deposit for any additional fees or charges incurred.
                15. You understand that violating these terms may result in termination of the rental agreement and additional fees.
                16. You agree to provide a refundable deposit at the time of rental, which will be used to cover any incidental charges or damages.
                17. You understand that the deposit will be refunded, minus any applicable charges, upon the satisfactory return of the vehicle.

                By checking the box below, you certify that you have read, understood, and agree to abide by these terms and conditions.
              </p>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="agreeToTerms"
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleInputChange}
                aria-invalid={errors.agreeToTerms ? "true" : "false"}
              />
              <Label htmlFor="agreeToTerms">I agree to the terms and conditions</Label>
            </div>
            {errors.agreeToTerms && <p className="text-red-500 text-sm mt-1">{errors.agreeToTerms}</p>}
          </div>

          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Rental Agreement'}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default RentalForm;