import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '../components/ui/dropdown-menu';
import { Plus } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '../lib/supabaseClient';

interface MaintenanceLog {
  id: number;
  vehicle_id: number;
  date: string;
  description: string;
  cost: string;
  vehicles: {
    id: number;
    make: string;
    model: string;
    year: number;
  };
}

interface Vehicle {
  id: number;
  make: string;
  model: string;
  year: number;
}

const VehicleMaintenancePage: React.FC = () => {
  const { user } = useUser();
  const [maintenanceLogs, setMaintenanceLogs] = useState<MaintenanceLog[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newLog, setNewLog] = useState({
    vehicle_id: '',
    date: '',
    description: '',
    cost: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedVehicle, setSelectedVehicle] = useState('all');
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (user) {
      fetchMaintenanceLogs();
      fetchVehicles();
    }
  }, [user]);

  useEffect(() => {
    calculateTotalCost();
  }, [maintenanceLogs, selectedVehicle]);

  const fetchMaintenanceLogs = async () => {
    try {
      const { data, error } = await supabase
        .from('maintenance_logs')
        .select(`
          *,
          vehicles (id, make, model, year)
        `)
        .order('date', { ascending: false });
      if (error) throw error;
      setMaintenanceLogs(data as MaintenanceLog[]);
    } catch (error) {
      console.error('Error fetching maintenance logs:', error);
      setError('Failed to fetch maintenance logs. Please try again.');
    }
  };

  const fetchVehicles = async () => {
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .select('*');
      if (error) throw error;
      setVehicles(data as Vehicle[]);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError('Failed to fetch vehicles. Please try again.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'cost') {
      const numericValue = value.replace(/[^0-9.]/g, '');
      setNewLog(prev => ({ ...prev, [name]: numericValue }));
    } else {
      setNewLog(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleVehicleChange = (value: string) => {
    setNewLog(prev => ({ ...prev, vehicle_id: value }));
  };

  const handleVehicleFilterChange = (value: string) => {
    setSelectedVehicle(value);
  };

  const handleAddLog = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('maintenance_logs')
        .insert(newLog)
        .select();
      if (error) throw error;
      await fetchMaintenanceLogs();
      setNewLog({ vehicle_id: '', date: '', description: '', cost: '' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding maintenance log:', error);
      setError(`Failed to add maintenance log: ${(error as Error).message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateTotalCost = () => {
    const filteredLogs = selectedVehicle === 'all' 
      ? maintenanceLogs 
      : maintenanceLogs.filter(log => log.vehicle_id.toString() === selectedVehicle);
    
    const total = filteredLogs.reduce((sum, log) => sum + parseFloat(log.cost), 0);
    setTotalCost(total);
  };

  const filteredLogs = selectedVehicle === 'all' 
    ? maintenanceLogs 
    : maintenanceLogs.filter(log => log.vehicle_id.toString() === selectedVehicle);

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Vehicle Maintenance</h1>
      
      {error && <div className="text-red-500">{error}</div>}
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Maintenance Logs</CardTitle>
          <Button onClick={() => setShowAddForm(!showAddForm)}>
            <Plus className="mr-2 h-4 w-4" /> Add New Log
          </Button>
        </CardHeader>
        <CardContent>
          <div className="mb-4 flex justify-between items-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-[200px]">
                  {selectedVehicle === 'all' ? 'Filter by vehicle' : vehicles.find(v => v.id.toString() === selectedVehicle)?.make + ' ' + vehicles.find(v => v.id.toString() === selectedVehicle)?.model}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-60 overflow-y-auto">
                <DropdownMenuItem onSelect={() => handleVehicleFilterChange('all')}>
                  All Vehicles
                </DropdownMenuItem>
                {vehicles.map((vehicle) => (
                  <DropdownMenuItem key={vehicle.id} onSelect={() => handleVehicleFilterChange(vehicle.id.toString())}>
                    {vehicle.make} {vehicle.model} ({vehicle.year})
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <div className="text-lg font-semibold">
              Total Maintenance Cost: ${totalCost.toFixed(2)}
            </div>
          </div>

          <AnimatePresence>
            {showAddForm && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Card className="mb-6">
                  <CardHeader>
                    <CardTitle>Add New Maintenance Log</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <form onSubmit={handleAddLog} className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <Label htmlFor="vehicle_id">Vehicle</Label>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="outline" className="w-full">
                                {newLog.vehicle_id ? vehicles.find(v => v.id.toString() === newLog.vehicle_id)?.make + ' ' + vehicles.find(v => v.id.toString() === newLog.vehicle_id)?.model : "Select a vehicle"}
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="max-h-60 overflow-y-auto">
                              {vehicles.map((vehicle) => (
                                <DropdownMenuItem key={vehicle.id} onSelect={() => handleVehicleChange(vehicle.id.toString())}>
                                  {vehicle.make} {vehicle.model} ({vehicle.year})
                                </DropdownMenuItem>
                              ))}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                        <div>
                          <Label htmlFor="date">Date</Label>
                          <Input id="date" name="date" type="date" value={newLog.date} onChange={handleInputChange} required />
                        </div>
                        <div className="col-span-2">
                          <Label htmlFor="description">Description</Label>
                          <Input id="description" name="description" value={newLog.description} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="cost">Cost</Label>
                          <Input 
                            id="cost" 
                            name="cost" 
                            type="text"
                            value={newLog.cost} 
                            onChange={handleInputChange} 
                            required 
                          />
                        </div>
                      </div>
                      <Button type="submit" disabled={isSubmitting || !newLog.vehicle_id}>
                        {isSubmitting ? 'Adding...' : 'Add Log'}
                      </Button>
                    </form>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Vehicle</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead>Cost</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredLogs.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{format(new Date(log.date), 'yyyy-MM-dd')}</TableCell>
                    <TableCell>{`${log.vehicles.make} ${log.vehicles.model} (${log.vehicles.year})`}</TableCell>
                    <TableCell>{log.description}</TableCell>
                    <TableCell>${parseFloat(log.cost).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default VehicleMaintenancePage;