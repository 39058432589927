import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Label } from '../components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Textarea } from '../components/ui/textarea';
import { Alert, AlertDescription } from '../components/ui/alert';
import { supabase } from '../lib/supabaseClient';

interface SettingsData {
  id?: string;
  late_fee: string;
  return_instructions: string;
}

const Settings = () => {
  const { user } = useUser();
  const [settings, setSettings] = useState<SettingsData>({
    late_fee: '',
    return_instructions: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      fetchSettings();
    }
  }, [user]);

  const fetchSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('settings')
        .select('*')
        .single();
      if (error) throw error;
      setSettings(data as SettingsData);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to fetch settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSettings(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    try {
      const { error } = await supabase
        .from('settings')
        .update(settings)
        .eq('id', settings.id);
      if (error) throw error;
      setSuccess('Settings updated successfully');
    } catch (error) {
      console.error('Error updating settings:', error);
      setError('Failed to update settings. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Card>
      <CardHeader>
        <CardTitle>System Settings</CardTitle>
      </CardHeader>
      <CardContent>
        {error && <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>}
        {success && <Alert><AlertDescription>{success}</AlertDescription></Alert>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="late_fee">Late Fee (per day)</Label>
            <Input
              type="number"
              id="late_fee"
              name="late_fee"
              value={settings.late_fee}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div>
            <Label htmlFor="return_instructions">Return Instructions</Label>
            <Textarea
              id="return_instructions"
              name="return_instructions"
              value={settings.return_instructions}
              onChange={handleInputChange}
              rows={5}
              required
            />
          </div>
          <Button type="submit">Save Settings</Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default Settings;