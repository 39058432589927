import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { Plus } from 'lucide-react';
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { Database } from '@/types/supabase';

interface Vehicle {
  id: number;
  make: string;
  model: string;
  year: number;
  license_plate: string;
  daily_rate: number;
  status: 'active' | 'inactive' | 'maintenance';
}

const VehicleManagement: React.FC = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [newVehicle, setNewVehicle] = useState<Omit<Vehicle, 'id'>>({ make: '', model: '', year: 0, license_plate: '', daily_rate: 0, status: 'active' });
  const [editingVehicle, setEditingVehicle] = useState<Vehicle | null>(null);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  const supabase = createClientComponentClient<Database>();

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setVehicles(data as Vehicle[]);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleAddVehicle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .insert([newVehicle])
        .select();

      if (error) throw error;

      setVehicles(prevVehicles => [...(data as Vehicle[]), ...prevVehicles]);
      setNewVehicle({ make: '', model: '', year: 0, license_plate: '', daily_rate: 0, status: 'active' });
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding vehicle:', error);
    }
  };

  const handleEditVehicle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editingVehicle) return;
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .update(editingVehicle)
        .eq('id', editingVehicle.id)
        .select();

      if (error) throw error;

      setVehicles(prevVehicles => prevVehicles.map(v => v.id === editingVehicle.id ? (data?.[0] as Vehicle || v) : v));
      setEditingVehicle(null);
    } catch (error) {
      console.error('Error updating vehicle:', error);
    }
  };

  const handleDeleteVehicle = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this vehicle?')) {
      try {
        const { error } = await supabase
          .from('vehicles')
          .delete()
          .eq('id', id);

        if (error) throw error;

        setVehicles(prevVehicles => prevVehicles.filter(v => v.id !== id));
      } catch (error) {
        console.error('Error deleting vehicle:', error);
      }
    }
  };

  const handleStatusChange = async (id: number, newStatus: Vehicle['status']) => {
    try {
      const { data, error } = await supabase
        .from('vehicles')
        .update({ status: newStatus })
        .eq('id', id)
        .select();

      if (error) throw error;

      setVehicles(prevVehicles => prevVehicles.map(v => v.id === id ? (data?.[0] as Vehicle || v) : v));
    } catch (error) {
      console.error('Error updating vehicle status:', error);
    }
  };

  const getStatusButtonColor = (status: Vehicle['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-500 hover:bg-green-600 text-white';
      case 'inactive':
        return 'bg-red-500 hover:bg-red-600 text-white';
      case 'maintenance':
        return 'bg-blue-500 hover:bg-blue-600 text-white';
      default:
        return 'bg-gray-500 hover:bg-gray-600 text-white';
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, formType: 'new' | 'edit' = 'new') => {
    const { name, value } = e.target;
    const numericValue = name === 'year' || name === 'daily_rate' ? parseFloat(value) : value;
    if (formType === 'edit' && editingVehicle) {
      setEditingVehicle(prev => {
        if (!prev) return null;
        return { ...prev, [name]: numericValue } as Vehicle;
      });
    } else {
      setNewVehicle(prev => ({ ...prev, [name]: numericValue }));
    }
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Vehicle Management</h1>
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Vehicle Inventory</CardTitle>
          <Button onClick={() => setShowAddForm(!showAddForm)}>
            <Plus className="mr-2 h-4 w-4" /> Add New Vehicle
          </Button>
        </CardHeader>
        <CardContent>
          <AnimatePresence>
            {showAddForm && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Card className="mb-6">
                  <CardHeader>
                    <CardTitle>Add New Vehicle</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <form onSubmit={handleAddVehicle} className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <Label htmlFor="make">Make</Label>
                          <Input id="make" name="make" value={newVehicle.make} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="model">Model</Label>
                          <Input id="model" name="model" value={newVehicle.model} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="year">Year</Label>
                          <Input id="year" name="year" type="number" value={newVehicle.year} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="license_plate">License Plate</Label>
                          <Input id="license_plate" name="license_plate" value={newVehicle.license_plate} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="daily_rate">Daily Rate</Label>
                          <Input id="daily_rate" name="daily_rate" type="number" step="0.01" value={newVehicle.daily_rate} onChange={handleInputChange} required />
                        </div>
                        <div>
                          <Label htmlFor="status">Status</Label>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="outline" className={`w-full justify-start ${getStatusButtonColor(newVehicle.status)}`}>
                                {newVehicle.status.charAt(0).toUpperCase() + newVehicle.status.slice(1)}
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56">
                              <DropdownMenuItem onSelect={() => setNewVehicle(prev => ({ ...prev, status: 'active' }))}>
                                Active
                              </DropdownMenuItem>
                              <DropdownMenuItem onSelect={() => setNewVehicle(prev => ({ ...prev, status: 'inactive' }))}>
                                Inactive
                              </DropdownMenuItem>
                              <DropdownMenuItem onSelect={() => setNewVehicle(prev => ({ ...prev, status: 'maintenance' }))}>
                                Maintenance
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>
                      <Button type="submit">Add Vehicle</Button>
                    </form>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Make</TableHead>
                  <TableHead>Model</TableHead>
                  <TableHead>Year</TableHead>
                  <TableHead>License Plate</TableHead>
                  <TableHead>Daily Rate</TableHead>
                  <TableHead className="min-w-[100px]">Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {vehicles.map((vehicle) => (
                  <React.Fragment key={vehicle.id}>
                    <TableRow>
                      <TableCell>{vehicle.make}</TableCell>
                      <TableCell>{vehicle.model}</TableCell>
                      <TableCell>{vehicle.year}</TableCell>
                      <TableCell>{vehicle.license_plate}</TableCell>
                      <TableCell>${vehicle.daily_rate.toFixed(2)}</TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="outline" className={`w-[100px] ${getStatusButtonColor(vehicle.status)}`}>
                              {vehicle.status.charAt(0).toUpperCase() + vehicle.status.slice(1)}
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem onSelect={() => handleStatusChange(vehicle.id, 'active')}>
                              Active
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={() => handleStatusChange(vehicle.id, 'inactive')}>
                              Inactive
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={() => handleStatusChange(vehicle.id, 'maintenance')}>
                              Maintenance
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-x-2">
                          <Button variant="outline" onClick={() => setEditingVehicle(vehicle)}>Edit</Button>
                          <Button onClick={() => handleDeleteVehicle(vehicle.id)} variant="destructive">Delete</Button>
                        </div>
                      </TableCell>
                    </TableRow>
                    {editingVehicle && editingVehicle.id === vehicle.id && (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <form onSubmit={handleEditVehicle} className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                              <div>
                                <Label htmlFor="edit-make">Make</Label>
                                <Input id="edit-make" name="make" value={editingVehicle.make} onChange={(e) => handleInputChange(e, 'edit')} required />
                              </div>
                              <div>
                                <Label htmlFor="edit-model">Model</Label>
                                <Input id="edit-model" name="model" value={editingVehicle.model} onChange={(e) => handleInputChange(e, 'edit')} required />
                              </div>
                              <div>
                                <Label htmlFor="edit-year">Year</Label>
                                <Input id="edit-year" name="year" type="number" value={editingVehicle.year} onChange={(e) => handleInputChange(e, 'edit')} required />
                              </div>
                              <div>
                                <Label htmlFor="edit-license_plate">License Plate</Label>
                                <Input id="edit-license_plate" name="license_plate" value={editingVehicle.license_plate} onChange={(e) => handleInputChange(e, 'edit')} required />
                              </div>
                              <div>
                                <Label htmlFor="edit-daily_rate">Daily Rate</Label>
                                <Input id="edit-daily_rate" name="daily_rate" type="number" step="0.01" value={editingVehicle.daily_rate} onChange={(e) => handleInputChange(e, 'edit')} required />
                              </div>
                              <div>
                                <Label htmlFor="edit-status">Status</Label>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="outline" className={`w-full justify-start ${getStatusButtonColor(editingVehicle.status)}`}>
                                      {editingVehicle.status.charAt(0).toUpperCase() + editingVehicle.status.slice(1)}
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    <DropdownMenuItem onSelect={() => setEditingVehicle(prev => {
                                      if (!prev) return null;
                                      return { ...prev, status: 'active' } as Vehicle;
                                    })}>
                                      Active
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onSelect={() => setEditingVehicle(prev => {
                                      if (!prev) return null;
                                      return { ...prev, status: 'inactive' } as Vehicle;
                                    })}>
                                      Inactive
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onSelect={() => setEditingVehicle(prev => {
                                      if (!prev) return null;
                                      return { ...prev, status: 'maintenance' } as Vehicle;
                                    })}>
                                      Maintenance
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <Button type="submit">Save Changes</Button>
                              <Button type="button" variant="outline" onClick={() => setEditingVehicle(null)}>Cancel</Button>
                            </div>
                          </form>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default VehicleManagement;