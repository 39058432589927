import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { SignIn, SignUp, SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AuthPage from './pages/AuthPage';
import Fleet from './pages/Fleet';
import Requirements from './pages/Requirements';
import ContactUs from './pages/ContactUs';
import BookingConfirmation from './pages/BookingConfirmation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import RentalForm from './pages/RentalForm';
import CustomerDashboard from './pages/CustomerDashboard';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import Overview from './pages/Overview';
import Bookings from './pages/admin/Bookings';
import Customers from './pages/admin/Customers';
import ExtensionRequests from './pages/ExtensionRequests';
import Settings from './pages/Settings';
import VehicleManagement from './pages/admin/VehicleManagement';
import ReportingAnalytics from './pages/ReportingAnalytics';
import LoyaltyProgram from './pages/LoyaltyProgram';
import VehicleMaintenancePage from './pages/VehicleMaintenancePage';
import Calendar from './pages/Calendar';

const MainLayout = () => (
  <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-700 via-purple-700 to-gray-900">
    <Header />
    <main className="flex-grow pt-20">
      <Outlet />
    </main>
    <Footer />
  </div>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/fleet" element={<Fleet />} />
          <Route path="/requirements" element={<Requirements />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/booking-confirmation" element={<BookingConfirmation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          <Route element={<SignedIn><Outlet /></SignedIn>}>
            <Route path="/rental-form" element={<RentalForm />} />
            <Route path="/customer-dashboard" element={<CustomerDashboard />} />
          </Route>

          <Route path="/admin" element={<SignedIn><AdminDashboard /></SignedIn>}>
            <Route index element={<Overview />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="customers" element={<Customers />} />
            <Route path="extension-requests" element={<ExtensionRequests />} />
            <Route path="settings" element={<Settings />} />
            <Route path="vehicle-management" element={<VehicleManagement />} />
            <Route path="reporting-analytics" element={<ReportingAnalytics />} />
            <Route path="loyalty-program" element={<LoyaltyProgram />} />
            <Route path="vehicle-maintenance" element={<VehicleMaintenancePage />} />
            <Route path="calendar" element={<Calendar />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;